import { createSlice } from "@reduxjs/toolkit";
import { LabelState } from "../interfaces/Label";

const initialState: LabelState = {
  isLoading: true,
  labels: [],
};

export const labelSlice = createSlice({
  name: "label",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
  },
});

export const { setLoading, setLabels } = labelSlice.actions;

export default labelSlice.reducer;
