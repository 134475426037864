import React, { useEffect, useState } from "react";
import {
  //  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
} from "antd";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser, updateUser } from "../../../store/services/user";
import { UserType } from "../../../store/interfaces/User";
import { passwordPattern } from "../../../config/constants";
import { countryData } from "../../../config/countryData";
import dayjs from "dayjs";

const CreateUser: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  // const [file, setFile] = useState(null);
  const [cities, setCities] = useState<Array<{ value: string; label: string }>>(
    []
  );
  const [isFreeze, setIsFreeze] = useState<boolean>(false);
  // const [imageSrc, setImageSrc] = useState(null);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.isEdit) {
      // state?.user?.imageUrl && setImageSrc(state?.user?.imageUrl);
      form.setFields([
        { name: ["firstName"], value: state?.user?.firstName },
        { name: ["lastName"], value: state?.user?.lastName },
        { name: ["email"], value: state?.user?.email },
        { name: ["phoneNumber"], value: state?.user?.phoneNumber },
        { name: ["points"], value: state?.user?.points || 0 },
        { name: ["addressLine"], value: state?.user?.addressLine },
        { name: ["residence"], value: state?.user?.residence },
        { name: ["state"], value: state?.user?.state },
        { name: ["city"], value: state?.user?.city },
        { name: ["zipCode"], value: state?.user?.zipCode },
        { name: ["isActive"], value: !state?.user?.isActive },
        { name: ["reason"], value: state?.user?.reason },
        { name: ["dateOfBirth"], value: dayjs(state?.user?.dateOfBirth) },
        { name: ["joiningDate"], value: dayjs(state?.user?.joiningDate) },
      ]);
      if (state?.user?.state) {
        setCities(
          countryData[state?.user?.state]?.map((v: string) => {
            return {
              value: v,
              label: v,
            };
          })
        );
      }
      if (!state?.user?.isActive) {
        setIsFreeze(true);
      }
    }
    // eslint-disable-next-line
  }, [state]);

  const onFinish = async (values: UserType) => {
    const firstName = values?.firstName;
    const lastName = values?.lastName;
    const email = values?.email;
    const password = values?.password ? values?.password : "";
    const phoneNumber = values?.phoneNumber;
    const points = values?.points ? values?.points : state?.user?.points || 0;
    // const picture = file ? file : state?.user?.imageUrl || "";
    const addressLine = values?.addressLine;
    const residence = values?.residence;
    const _state = values?.state;
    const city = values?.city;
    const zipCode = values?.zipCode
      ? values?.zipCode
      : state?.user?.zipCode || "";
    const isActive = values?.isActive ? false : true;
    const reason = values?.isActive ? values?.reason : "";
    const dateOfBirth = values?.dateOfBirth?.toISOString();
    const joiningDate = values?.joiningDate?.toISOString();

    // if (file) {
    //   let formdata = new FormData();
    //   formdata.append("_id", state?.user?._id ? state?.user?._id : null);
    //   formdata.append("pictures", picture);
    //   formdata.append("firstName", firstName);
    //   formdata.append("lastName", lastName);
    //   formdata.append("email", email);
    //   formdata.append("password", password);
    //   formdata.append("phoneNumber", phoneNumber);
    //   formdata.append("points", points);
    //   formdata.append("addressLine", addressLine);
    //   formdata.append("residence", residence);
    //   formdata.append("country", "United State");
    //   formdata.append("state", _state);
    //   formdata.append("city", city);
    //   formdata.append("zipCode", zipCode);
    //   formdata.append("isActive", isActive);

    //   state?.isEdit ? _updateUser(formdata) : _createUser(formdata);
    // } else {
    // }
    const obj = {
      _id: state?.user?._id ? state?.user?._id : null,
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      points,
      addressLine,
      residence,
      country: "United States",
      state: _state,
      city,
      zipCode,
      isActive,
      reason,
      dateOfBirth,
      joiningDate
    };
    state?.isEdit ? _updateUser(obj) : _createUser(obj);
  };

  const _createUser = async (payload: any) => {
    setLoading(true);
    const res = await createUser(payload);
    if (res) {
      form.resetFields();
      navigate("/user");
    }
    setLoading(false);
    // setFile(null);
  };

  const _updateUser = async (payload: any) => {
    setLoading(true);
    const res = await updateUser(payload);
    if (res) {
      form.resetFields();
      navigate("/user");
    }
    setLoading(false);
  };

  // const handleFile = (file: any) => {
  //   setFile(file);
  //   const src: any = URL.createObjectURL(file);
  //   setImageSrc(src);
  // };

  return (
    <div className="create_user_container">
      <header>
        <div>
          <IoArrowBack
            size={20}
            className="cursor"
            onClick={() => navigate(-1)}
          />
          <h1>{state?.isEdit ? "Update User" : "Create User"}</h1>
        </div>
      </header>

      <div className="form_container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            e.isActive === true && setIsFreeze(true);
            e.isActive === false && setIsFreeze(false);
            if (e.state) {
              setCities(
                countryData[e.state]?.map((v: string) => {
                  return {
                    value: v,
                    label: v,
                  };
                })
              );
            }
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <h2 style={{ marginBottom: "10px" }}>User Information</h2>
            </Col>
            {/* <Col xs={24}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Avatar
                  size={55}
                  src={
                    imageSrc
                      ? imageSrc
                      : require("../../../assets/images/user-icon.jpg")
                  }
                />
                <Form.Item name={"pictures"} label="Profile Picture">
                  <>
                    <label className="file_button" htmlFor="fileInput">
                      <input
                        type="file"
                        hidden
                        id="fileInput"
                        onChange={(e: any) => handleFile(e?.target?.files[0])}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </>
                </Form.Item>
              </div>
            </Col> */}
            <Col xs={24} sm={12}>
              <Form.Item
                name={"firstName"}
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="First Name (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"lastName"}
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Last Name (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"email"}
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    type: "email",
                    message: "Invalid email address",
                  },
                ]}
              >
                <Input type="email" placeholder="Email Address (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"password"}
                label={
                  <p>
                    Password (
                    <span style={{ fontSize: "10px" }}>
                      One uppercase letter, one special character and one number
                    </span>
                    )
                  </p>
                }
                rules={[
                  {
                    validator: async (_, value) => {
                      if (state?.isEdit && !value) {
                        return Promise.resolve();
                      }
                      if (!value) {
                        return Promise.reject("Required");
                      }
                      if (value.length < 8) {
                        return Promise.reject(
                          "Password must be at least 8 characters"
                        );
                      }
                      if (passwordPattern.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Invalid password pattern (Password must contain atleast one uppercase letter, one special character and one number)"
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input placeholder="Password (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Date of Birth"
                name={"dateOfBirth"}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <>
                  {/* @ts-ignore */}
                  <DatePicker format={"MM/DD/YYYY"} style={{ width: "100%" }} size="large" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Work Anniversary Date"
                name={"joiningDate"}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <>
                  {/* @ts-ignore */}
                  <DatePicker format={"MM/DD/YYYY"} style={{ width: "100%" }} size="large" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"phoneNumber"}
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input type="number" placeholder="Phone Number (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"points"}
                label="Points"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Input type="number" placeholder="Points (Optional)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <h2 style={{ marginBlock: "10px" }}>Address</h2>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"addressLine"}
                label="Address Line 1"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Address Line 1 (Optional)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"residence"}
                label="Suite/Apt/Floor #"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Suite/Apt/Floor # (Optional)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"state"}
                label="State"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={
                    <span className="select-placeholder">Select State</span>
                  }
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={Object?.keys(countryData)?.map((v) => {
                    return {
                      value: v,
                      label: v,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={
                    <span className="select-placeholder">Select city</span>
                  }
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Zip Code"
                name="zipCode"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  size="large"
                  type="number"
                  placeholder="Enter zip code"
                />
              </Form.Item>
            </Col>
            {state?.isEdit && (
              <>
                <Col xs={24}>
                  <h2 style={{ marginBlock: "10px" }}>Account</h2>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label=""
                    name="isActive"
                    rules={[
                      {
                        required: false,
                        message: "Required",
                      },
                    ]}
                    valuePropName="checked"
                  >
                    <Checkbox>Freeze Account</Checkbox>
                  </Form.Item>
                </Col>
              </>
            )}
            {isFreeze && (
              <Col xs={24}>
                <Form.Item
                  name={"reason"}
                  label="Reason"
                  rules={[
                    {
                      required: isFreeze ? true : false,
                      message: "Required",
                    },
                  ]}
                >
                  <Input placeholder="Reason (Required)" />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  {state?.isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CreateUser;
