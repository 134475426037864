export interface Theme {
  primary: string;
  siderBg: string;
  headerBg: string;
  footerBg: string;
  border: string;
  white: string;
  black: string;
  borderLight: string;
  mainBlue: string;
  mainYellow: string;
  mainRed: string;
  mainGreen: string;
  lightGreen: string;
  lightBlue: string;
  gray: string;
  skyBlue: string;
  mainGradient: string;
  placeholder: string;
}

export const lightTheme: Theme = {
  primary: "#7E549F",
  siderBg: "#001529",
  headerBg: "rgba(255, 255, 255, 0.1)",
  footerBg: "#fff",
  border: "#f0f0f0",
  white: "#ffff",
  black: "#000",
  borderLight: "#d9d9d9",
  mainBlue: "#1E6CB6",
  mainYellow: "#FADD07",
  mainRed: "#F03D40",
  mainGreen: "#007F00",
  lightGreen: "#CDFFCD",
  lightBlue: "#ADD8E6",
  gray: "#E5E5E5",
  skyBlue: "#4DA8DD",
  mainGradient: "linear-gradient(91deg, #F03D40 -0.73%, #FADD07 118.71%)",
  placeholder: "rgba(0,0,0,0.4)",
};

export const darkTheme: Theme = {
  primary: "#7E549F",
  siderBg: "#001529",
  headerBg: "rgba(255, 255, 255, 0.5)",
  footerBg: "#fff",
  border: "#f0f0f0",
  white: "#ffff",
  black: "#000",
  borderLight: "#d9d9d9",
  mainBlue: "#1E6CB6",
  mainYellow: "#FADD07",
  mainRed: "#F03D40",
  mainGreen: "#007F00",
  lightGreen: "#CDFFCD",
  lightBlue: "#ADD8E6",
  gray: "#E5E5E5",
  skyBlue: "#4DA8DD",
  mainGradient: "linear-gradient(91deg, #F03D40 -0.73%, #FADD07 118.71%)",
  placeholder: "rgba(0,0,0,0.4)",
};
