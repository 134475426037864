import React, { useState } from "react";

import { Modal, Button, notification } from "antd";

import { MdOutlineDone } from "react-icons/md";
import { updateOrderStatus } from "../../store/services/order";
import { firstLetterCap } from "../../utils";
import { updateWishlistStatus } from "../../store/services/groupWishlist";

interface Props {
  id: string;
  status: string;
}

const GroupWishlistStatus: React.FC<Props> = ({ id, status }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelected("");
  };

  const handleUpdate = async () => {
    if (status === "pending" && selected !== "") {
      setIsLoading(true);
      const res = await updateWishlistStatus({
        _id: id,
        orderStatus: selected,
      });
      if (res) {
        handleCancel();
      }
      setIsLoading(false);
    }
  };

  const showError = () => {
    notification.error({
      message: `${firstLetterCap(status)} order cannot be changed.`,
    });
  };

  const getColor = (key: string) => {
    let color: { [key: string]: string } = {
      pending: "lightOrange",
      dispatched: "lightGreen",
      approved: "lightBlue",
      cancelled: "lightRed",
    };
    return color[key];
  };

  return (
    <>
      {status === "pending" ? (
        <div onClick={showModal} className={`status_box ${getColor(status)}`}>
          <span>{firstLetterCap(status)}</span>
        </div>
      ) : (
        <div onClick={showError} className={`status_box ${getColor(status)}`}>
          <span>{firstLetterCap(status)}</span>
        </div>
      )}
      <Modal
        forceRender
        title={"Update Status"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="status_modal"
        centered
      >
        <div className="status_modal_wrapper">
          <div className="status_button_box">
            {status === "pending" ? (
              ["approved", "cancelled"].map((v) => {
                return (
                  <Button
                    key={v}
                    className={`${getColor(v)}`}
                    onClick={() => setSelected(v)}
                  >
                    {selected === v && (
                      <MdOutlineDone
                        size={18}
                        style={{ marginRight: "5px", marginBottom: "-4px" }}
                      />
                    )}
                    <span>{firstLetterCap(v)}</span>
                  </Button>
                );
              })
            ) : (
              <span></span>
            )}
          </div>
          <div className="status_modal_footer">
            <Button className="buttonOutlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="button"
              type="primary"
              loading={isLoading}
              onClick={handleUpdate}
              disabled={!selected}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default GroupWishlistStatus;
