import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { CreateScheduleType } from "../interfaces/Schedule";
import { setLoading, setSchedules } from "../slices/scheduleSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Schedule Created Successfully";
const Updated = "Schedule Updated Successfully";
const Deleted = "Schedule Deleted Successfully";

export const createSchedule = async (payload: CreateScheduleType) => {
  try {
    attachToken();
    const response = await privateAPI.post("/schedule/create", payload);
    notification.success({
      message: response?.data?.data?.message || Created,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSchedule = async () => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/schedule/get");
    if (response) {
      store.dispatch(setSchedules(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateSchedule = async (payload: CreateScheduleType) => {
  try {
    attachToken();
    const response = await privateAPI.put("/schedule/update", payload);
    notification.success({
      message: response?.data?.data?.message || Updated,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteSchedule = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.delete(
      `/schedule/delete?scheduleId=${id}`
    );
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
