import { createSlice } from "@reduxjs/toolkit";

import { GroupWishlistState } from "../interfaces/GroupWishlist";

const initialState: GroupWishlistState = {
  isLoading: true,
  groupWishlistData: [],
  total: 0,
};

export const groupWishlistSlice = createSlice({
  name: "groupWishlist",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setGroupWishlist: (state, action) => {
      state.total = action.payload.total;
      state.groupWishlistData = action.payload;
    },
  },
});

export const { setLoading, setGroupWishlist } = groupWishlistSlice.actions;

export default groupWishlistSlice.reducer;
