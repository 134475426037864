import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";
import { setLoading, setNotificationsGroup } from "../slices/notificationSettingsSlice";
import { setLoadingNotifications, setNotificationCount, setNotificationUnreadCount, setNotifications } from "../slices/notificationSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Notification Created Successfully";
const Deleted = "Notification Deleted Successfully";

export const createNotification = async (payload: any) => {
  try {
    attachToken();
    const response = await privateAPI.post("/notification/create-notification-group", payload);
    notification.success({
      message: response?.data?.data?.message || Created,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};



export const getNotification = async (payload: any) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/notification/get-notification-group", payload);
    if (response) {
      store.dispatch(setNotificationsGroup(response?.data?.data?.notificationGroup));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteNotification = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(
      `/notification/delete-notification-group`, {_id: id}
    );
    if(response.status === 200){
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
      return response.status;
    }
    
  } catch (error: any) {
    handleError(error);
  }
};


export const getAllNotifications = async (payload: any) => {
  try {
    attachToken();
    store.dispatch(setLoadingNotifications(true));
    const response = await privateAPI.post('/notification/get-all-admin', payload);
    if (response) {
      store.dispatch(setNotifications(response?.data.notifications));
      store.dispatch(setNotificationCount(response?.data?.total));
    }
    return response;
  } catch (error: any) {
    console.log("GET NOTIFICATIONS ERROR: ", error);
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingNotifications(false));
  }
};

export const updateNotifications = async (data: any) => {
  try {
    const res = await privateAPI.post("/notification/read-notifications", { ids: data });
    if (res.status === 200) {
      notification.success({ message: "Notification read" });
      return res.status;
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const getNotificationUnreadCount = async () => {
  try {
    attachToken();
    store.dispatch(setLoadingNotifications(true));
    const response = await privateAPI.get('/notification/get-counts-admin');
    if (response) {
      store.dispatch(setNotificationUnreadCount(response?.data?.unreadCount));
    }
    return response?.data?.data?.unread;
  } catch (error: any) {
      
  } finally {
      store.dispatch(setLoadingNotifications(false));
  }
}


