import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import SiderDrawer from "./SiderDrawer";

const { Content } = Layout;

const MobileLayout: React.FC = () => {
  return (
    <Layout style={{ height: "100vh" }} className="m-layout">
      <SiderDrawer />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MobileLayout;
