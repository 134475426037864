import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { OrderState } from "../interfaces/Order";

const initialState: OrderState = {
  isLoading: true,
  total: 0,
  orders: [],
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setOrders: (state, action) => {
      state.total = action.payload.total;
      state.orders = action.payload.orders;
    },
  },
});

export const { setOrders, setLoading } = orderSlice.actions;

export const getOrders = (state: RootState) => state.order.orders;

export default orderSlice.reducer;
