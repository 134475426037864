import React, { useEffect, useState } from "react";
import { Button, Menu } from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../store/services/auth";

import { LogoutOutlined } from "@ant-design/icons";
import { LuLayoutDashboard } from "react-icons/lu";
import {
  TbUsersGroup,
  TbShoppingCartHeart,
  TbShoppingCart,
} from "react-icons/tb";
import { BsBoxes } from "react-icons/bs";
import { BsCartCheck } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { FaRegStar } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { IoDocumentOutline } from "react-icons/io5";
import {
  MdLabelImportantOutline,
  MdNotifications,
  MdOutlineReviews,
  MdOutlineSettings,
} from "react-icons/md";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { LuBadge } from "react-icons/lu";

interface Props {
  visible?: Boolean;
  setVisible?: (value: boolean) => void;
}

const SiderMenu: React.FC<Props> = ({ visible, setVisible }) => {
  const headerHeight = 70;
  const footerHeight = 70;
  const { pathname } = useLocation();
  const iconSize = 18;

  const getActiveKey = () => {
    switch (pathname) {
      case "/dashboard": {
        return ["1"];
      }
      case "/user": {
        return ["2"];
      }
      case "/create-user": {
        return ["2"];
      }
      case "/product": {
        return ["3"];
      }
      case "/create-product": {
        return ["3"];
      }
      case "/order": {
        return ["4"];
      }
      case "/cart": {
        return ["5"];
      }
      case "/wishlist": {
        return ["6"];
      }
      case "/group-wishlist": {
        return ["7"];
      }
      case "/category": {
        return ["8"];
      }
      case "/reward": {
        return ["9"];
      }
      case "/schedule": {
        return ["10"];
      }
      case "/review": {
        return ["11"];
      }
      case "/label": {
        return ["12"];
      }
      case "/badge": {
        return ["13"]
      }
      case "/account": {
        return ["14"];
      }
      case "/page": {
        return ["15"];
      }
      case "/notifications": {
        return ["16"];
      }
      case "/view-notifications": {
        return ["17"];
      }
      case "/settings": {
        return ["18"];
      }

      default:
        return ["1"];
    }
  };
  const [active, setActive] = useState(getActiveKey);
  const navigate = useNavigate();

  const handleMenu = (event: { key: string }) => {
    visible && setVisible && setVisible(false);
    switch (event.key) {
      case "1":
        navigate("/dashboard");
        break;
      case "2":
        navigate("/user");
        break;
      case "3":
        navigate("/product");
        break;
      case "4":
        navigate("/order");
        break;
      case "5":
        navigate("/cart");
        break;
      case "6":
        navigate("/wishlist");
        break;
      case "7":
        navigate("/group-wishlist");
        break;
      case "8":
        navigate("/category");
        break;
      case "9":
        navigate("/reward");
        break;
      case "10":
        navigate("/schedule");
        break;
      case "11":
        navigate("/review");
        break;
      case "12":
        navigate("/label");
        break;
      case "13":
        navigate("/badge");
        break;
      case "14":
        navigate("/account");
        break;
      case "15":
        navigate("/page");
        break;
      case "16":
        navigate("/notifications");
        break;
      case "17":
        navigate("/view-notifications");
        break;
      case "18":
        navigate("/settings");
        break;
      default:
        navigate("/dashboard");
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    setActive(getActiveKey());
  }, [pathname]);

  return (
    <>
      <div className="sider_top" style={{ height: `${headerHeight}px` }}>
        <img src={require("../../assets/images/logo.png")} alt={"Logo"} />
        <h2 style={{ color: "#fff", textAlign: "center" }}>OCA</h2>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={active}
        selectedKeys={active}
        onClick={handleMenu}
        style={{
          height: `calc(100vh - (${headerHeight + footerHeight}px))`,
        }}
        items={[
          {
            key: "1",
            icon: <LuLayoutDashboard size={iconSize} />,
            label: "Dashboard",
          },
          {
            key: "2",
            icon: <TbUsersGroup size={iconSize} />,
            label: "Users",
          },
          {
            key: "3",
            icon: <BsBoxes size={iconSize} />,
            label: "Products",
          },
          {
            key: "4",
            icon: <BsCartCheck size={iconSize} />,
            label: "Orders",
          },
          {
            key: "5",
            icon: <TbShoppingCart size={iconSize} />,
            label: "Carts",
          },
          {
            key: "6",
            icon: <TbShoppingCartHeart size={iconSize} />,
            label: "Wishlist",
          },
          {
            key: "7",
            icon: <HiOutlineRectangleGroup size={iconSize} />,
            label: "Group Wishlist",
          },
          {
            key: "8",
            icon: <BiCategory size={iconSize} />,
            label: "Categories",
          },
          {
            key: "10",
            icon: <AiOutlineSchedule size={iconSize} />,
            label: "Schedule",
          },
          {
            key: "11",
            icon: <MdOutlineReviews size={iconSize} />,
            label: "Reviews",
          },
          {
            key: "12",
            icon: <MdLabelImportantOutline size={iconSize} />,
            label: "Labels",
          },
          {
            key: "13",
            icon: <LuBadge size={iconSize} />,
            label: "Badges",
          },
          {
            key: "14",
            icon: <RiAdminLine size={iconSize} />,
            label: "Account",
          },
          {
            key: "15",
            icon: <IoDocumentOutline size={iconSize} />,
            label: "Banner",
          },
          {
            key: "16",
            icon: <MdNotifications size={iconSize} />,
            label: "Manage Notifications"
          },
          {
            key: "17",
            icon: <MdNotifications size={iconSize} />,
            label: "Notifications"
          },
          {
            key: "18",
            icon: <MdOutlineSettings size={iconSize} />,
            label: "Settings",
          },
        ]}
      />
      <div className="sider_bottom" style={{ height: `${footerHeight}px` }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </>
  );
};

export default SiderMenu;
