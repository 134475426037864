import { createSlice } from "@reduxjs/toolkit";
import { BadgeState } from "../interfaces/Badge";

const initialState: BadgeState = {
  isLoading: true,
  badges: [],
};

export const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setBadges: (state, action) => {
      state.badges = action.payload;
    },
  },
});

export const { setLoading, setBadges } = badgeSlice.actions;

export default badgeSlice.reducer;
