import React, { useEffect } from "react";

import { Badge, Layout } from "antd";

import { Outlet, useNavigate } from "react-router-dom";
import SiderMenu from "./SiderMenu";
import { useAppSelector } from "../../store/hooks";
import { FaBell } from "react-icons/fa";
import { getNotificationUnreadCount } from "../../store/services/notification";

const {
  Header,
  Sider,
  Content,
  // Footer
} = Layout;

const VerticalLayout: React.FC = () => {
  const navigate = useNavigate();
  const { user: ADMIN } = useAppSelector((state) => state.auth);
  const { notificationUnreadCount } = useAppSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getNotificationUnreadCount();
  };
  return (
    <Layout style={{ height: "100vh" }} className="v-layout">
      <Sider trigger={null}>
        <SiderMenu />
      </Sider>
      <Layout>
        <Header>
          <div className="layout_header">
            {/* <h3></h3> */}
            <div className="user_detail">
              <div className="name_box">
                <h4>
                  {ADMIN?.firstName} {ADMIN?.lastName}
                </h4>
                <p>Admin</p>
              </div>
              <div className="users_image_box">
                <img
                  src={require("../../assets/images/user-icon.jpg")}
                  alt="Icon"
                />
              </div>
            </div>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate("/view-notifications")
                }}
              >
                <Badge size="default" count={notificationUnreadCount}>
                  <FaBell size={22} className="cursor" />
                </Badge>
              </div>
          </div>
        </Header>
        <Content>
          <Outlet />
        </Content>
        {/* <Footer></Footer> */}
      </Layout>
    </Layout>
  );
};

export default VerticalLayout;
