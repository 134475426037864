import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { setOrders, setLoading } from "../slices/orderSlice";
import { GetOrderType, UpdateStatusType } from "../interfaces/Order";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

// const Created = "Order Created Successfully";
// const Updated = "Order Updated Successfully";
const Deleted = "Order Deleted Successfully";

export const getOrders = async (payload: GetOrderType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/order/get-all-order", {
      params: payload,
    });
    if (response) {
      store.dispatch(setOrders(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateOrderStatus = async (payload: UpdateStatusType) => {
  try {
    attachToken();
    const response = await privateAPI.post("/order/update", payload);
    if (response) {
      getOrders({
        search: "",
        perPage: 10,
        page: 1,
      });
    }
    return response;
  } catch (error: any) {
    console.log(
      "Error update status:",
      error?.response?.data?.message?.message
    );
    notification.error({
      message: error?.response?.data?.message?.message || "Server error",
    });
  }
};

export const deleteOrder = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.delete(`/order/delete?orderId=${id}`);
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
