import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { CartState } from "../interfaces/Cart";

const initialState: CartState = {
  isLoading: true,
  total: 0,
  carts: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCarts: (state, action) => {
      state.total = action.payload.total;
      state.carts = action.payload.carts;
    },
  },
});

export const { setLoading, setCarts } = cartSlice.actions;

export const getCarts = (state: RootState) => state.cart.carts;

export default cartSlice.reducer;
