import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";

import moment from "moment";
import { FaEye } from "react-icons/fa6";
import { NotificationStatusIcon } from "../../../config/NotificationConstants";
import { NotificationType } from "../../../store/interfaces/Notification";
import { getAllNotifications, getNotificationUnreadCount, updateNotifications } from "../../../store/services/notification";

const Notifications: React.FC = () => {
  const { loadingNotifications, notifications, notificationCount } = useAppSelector((state) => state.notifications);
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [data, setData] = useState<any>(null);
  const [btn, setBtn] = useState('unread');
  const [limit,setLimit]= useState<number>(10);
  const [page, setPage] = useState(1);

  const showNotificationModal =async (obj: any) => {
    setData(obj);
    setModalVisible(true);
    const id = [obj._id];

    if (btn === 'unread') {
      try {
        const res = await updateNotifications(id);
        if (res === 200) {
          getData();
        }
      } catch (error) {
        console.error("Failed to update notifications:", error);
      }
    }
  };
  const getColumns = () => {
    const columns: any = [
      {
        key: "type",
        render: (_: string, obj: NotificationType) => (
          // @ts-ignore
          NotificationStatusIcon[obj.type]
        )
      },
      {
        dataIndex: "message",
        key: "message",
        render: (_: string, obj: NotificationType) => (
          obj?.message.length > 10 ? (
            <div>
              <p>{obj?.message.split(" ").slice(0, 10).join(" ") + " ..."}</p>
              <p style={{ marginTop: 20 }}>{moment(obj?.date).format("MM/DD/YYYY")}</p>
            </div>
          ) : (
            <div>{obj?.message}</div>
          )
        )
      },
      {
        dataIndex: "imageUrl",
        key: "imageUrl",
        render: (_: string, obj: any) => (
          <div className="icon_box">
            <FaEye cursor={"pointer"} size={30} onClick={() => showNotificationModal(obj)} />
          </div>
        ),
      }
    ];
    return columns;
  };

  
  const getData = async () => {
    const payload = {
      page: page,
      limit: limit,
      read: btn === 'unread' ? false : true
    };
    const res = await getAllNotifications(payload);
    await getNotificationUnreadCount();
  };

  useEffect(() => {
    getData();
  }, [page,btn,limit]);


  useEffect(()=>{
    window.scroll(0,0);
  },[])

  return (
    <div>
      <div className="notification_settings_Header_Container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h2>Notifications</h2>
        </div>
      </div>
      <div className="notification_btn_main">
        <div onClick={() => { setBtn("unread") }} className={btn === "unread" ? "notifiction_active" : "notifiction_btn"}>
          Unread
        </div>
        <div onClick={() => { setBtn("read") }} className={btn === "read" ? "notifiction_active" : "notifiction_btn"}>
          Read
        </div>
      </div>
      <div className="notification_table_container  table-div">
        <Table
          loading={loadingNotifications}
          columns={getColumns()}
          dataSource={notifications}
          scroll={{ x: 600 }}
          pagination={{
            current: page,
            total: notificationCount,
            onChange: (val) => {
              setPage(val);
            },
            onShowSizeChange(current, size) {
              setLimit(size);
              setPage(1); 
            },
            position: ["bottomRight"],
          }}
        />
      </div>
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <h2 className="notification_modal_heading">{"Message"}</h2>
        <p>{data?.message}</p>
      </Modal>
    </div>
  );
};

export default Notifications;
