import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { useAppSelector } from "../../../../store/hooks";
import { updateSettings } from "../../../../store/services/settings";
import dayjs from "dayjs";
import { CreateSettingOnFinishType } from "../../../../store/interfaces/Settings";

const DevliveryFeeTab: React.FC = () => {
  const { settings } = useAppSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      form.setFieldValue("deliveryFee", settings?.deliveryFee || 0);
      form.setFieldValue("sharedLimit", settings?.sharedLimit || 0);
      form.setFieldValue("exceedDate", dayjs(settings?.exceedDate));
    }

    // eslint-disable-next-line
  }, [settings]);

  const onFinish = async (values: CreateSettingOnFinishType) => {
    setLoading(true);
    const payload = {
      deliveryFee: Number(values.deliveryFee),
      sharedLimit: Number(values.sharedLimit),
      exceedDate: values?.exceedDate
        ? values?.exceedDate?.toISOString()
        : new Date().toISOString(),
    };
    await updateSettings(payload);
    setLoading(false);
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} md={10} lg={8}>
            <Form.Item
              name={"deliveryFee"}
              label="Global Delivery Fee"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number" placeholder="Delivery Fee" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} md={10} lg={8}>
            <Form.Item
              name={"sharedLimit"}
              label="Reward Share Limit"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number" placeholder="Reward share limit" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10} lg={8}>
            <Form.Item
              name={"exceedDate"}
              label="Exceed Date"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <>
                {/* @ts-ignore */}
                <DatePicker
                  size="large"
                  format={"MM/DD/YYYY"}
                  disabledDate={(current: dayjs.Dayjs) =>
                    current.isBefore(dayjs().subtract(1, "day"))
                  }
                />
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Button loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DevliveryFeeTab;
