import React, { useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  // Avatar
} from "antd";

import { FaRegEye } from "react-icons/fa";

import { UserType } from "../../store/interfaces/User";
import moment from "moment";

interface Props {
  data: UserType;
}

const ViewUser: React.FC<Props> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FaRegEye className="icon" onClick={showModal} />
      <Modal
        forceRender
        title={"View User"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="view_user_modal"
        centered
      >
        <Divider />
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24}>
            <h2>Profile Information</h2>
          </Col>
          {/* <Col xs={24} sm={24}>
            <Avatar
              size={80}
              src={
                data?.imageUrl
                  ? data?.imageUrl
                  : require("../../assets/images/user-icon.jpg")
              }
            />
          </Col> */}
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Verified</p>
              <p>{data?.emailVerified ? "Yes" : "No"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>User Since</p>
              <p>{moment(data?.createdAt).format("MMM DD, YYYY")}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>User Name</p>
              <p>
                {data?.firstName} {data?.lastName}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Date of Birth</p>
              <p>
                {data?.dateOfBirth
                  ? moment(data?.dateOfBirth).format("MM DD, YYYY")
                  : "-"}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Joining Date</p>
              <p>
                {data?.joiningDate
                  ? moment(data?.joiningDate).format("MMM DD, YYYY")
                  : "-"}
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Email Address</p>
              <p>{data?.email}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Phone Number</p>
              <p>{data?.phoneNumber || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Points</p>
              <p>{data?.points || "0"}</p>
            </div>
          </Col>
          <Col xs={24} sm={24}>
            <h2>Address</h2>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Address Line</p>
              <p>{data?.addressLine || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Suite/Apt/Floor #</p>
              <p>{data?.residence || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>State</p>
              <p>{data?.state || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>City</p>
              <p>{data?.city || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Zip Code</p>
              <p>{data?.zipCode || "-"}</p>
            </div>
          </Col>
          <Col xs={24} sm={24}>
            <h2>Account</h2>
          </Col>
          <Col xs={24} sm={12}>
            <div className="row">
              <p>Account Freezed</p>
              <p>{data?.isActive ? "No" : "Yes"}</p>
            </div>
          </Col>
          {!data?.isActive && (
            <Col xs={24}>
              <div className="row">
                <p>Reason</p>
                <p>{data?.reason}</p>
              </div>
            </Col>
          )}
          <Col xs={24}>
            <div className="view_user_footer">
              <Button className="buttonOutlined" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export { ViewUser };
