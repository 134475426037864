import store from "..";
import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import { setLoading, setSettings } from "../slices/settingsSlice";
import { CreateSettingType } from "../interfaces/Settings";

export const getSettings = async () => {
  try {
    store.dispatch(setLoading(true));
    attachToken();
    const response = await privateAPI.get("/settings/get-settings");
    if (response) {
      store.dispatch(setSettings(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET SETTINGS ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateSettings = async (payload: CreateSettingType) => {
  try {
    attachToken();
    const response = await privateAPI.post(
      "/settings/update-settings",
      payload
    );
    notification.success({
      message: response?.data?.data?.message || "Settings Updated!",
    });
    return response;
  } catch (error: any) {
    console.log("UPDATE SETTING ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};
