import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { CreateReviewType } from "../interfaces/Review";
import { setLoading, setReviews } from "../slices/reviewSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Review Created Successfully";
const Updated = "Review Updated Successfully";
const Deleted = "Review Deleted Successfully";

export const createReview = async (payload: CreateReviewType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/reviews/create", payload);
    if (response) {
      await getReviews();
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getReviews = async () => {
  try {
    store.dispatch(setLoading(true));
    attachToken();
    const response = await privateAPI.get("/reviews/get-all");
    if (response) {
      store.dispatch(setReviews(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateReview = async (payload: CreateReviewType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.put("/reviews/update", payload);
    if (response) {
      await getReviews();
      notification.success({
        message: response?.data?.data?.message || Updated,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteReview = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/reviews/delete`, { _id: id });
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
