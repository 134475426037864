import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { UserState } from "../interfaces/User";

const initialState: UserState = {
  isLoading: true,
  total: 0,
  users: [],
  scheduleUsers: [],
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.total = action.payload.total;
      state.users = action.payload.users;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsersSchedule: (state, action) => {
      state.scheduleUsers = action.payload;
    },
  },
});

export const { setLoading, setUsers, setUsersSchedule } = authSlice.actions;

export const getUsers = (state: RootState) => state.user.users;

export default authSlice.reducer;
