import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { useAppSelector } from "../../../../store/hooks";

import { TbUsersGroup, TbShoppingCartHeart } from "react-icons/tb";
import { BsBoxes } from "react-icons/bs";
import {
  MdOutlineShoppingCart,
  MdOutlineShoppingCartCheckout,
} from "react-icons/md";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { getDashboardAnalytic } from "../../../../store/services/dashboard";

const Analytic: React.FC = () => {
  const { analytics } = useAppSelector((state) => state.dashboard);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    await getDashboardAnalytic();
    setLoading(false);
  };

  return (
    <Row gutter={[30, 30]}>
      {[
        {
          name: "Total User",
          total: analytics?.totalUsers,
          icon: <TbUsersGroup size={25} color="var(--mainBlue)" />,
        },
        {
          name: "Total Products",
          total: analytics?.totalProducts,
          icon: <BsBoxes size={25} color="var(--mainBlue)" />,
        },
        {
          name: "Total Carts",
          total: analytics?.totalCarts,
          icon: <MdOutlineShoppingCart size={25} color="var(--mainBlue)" />,
        },
        {
          name: "Total Wishlists",
          total: analytics?.totalWishlists,
          icon: <TbShoppingCartHeart size={25} color="var(--mainBlue)" />,
        },
        {
          name: "Total Orders",
          total: analytics?.totalOrders,
          icon: (
            <MdOutlineShoppingCartCheckout size={25} color="var(--mainBlue)" />
          ),
        },
        {
          name: "Total Group Wishlists",
          total: analytics?.totalGroupWishlists,
          icon: <HiOutlineRectangleGroup size={25} color="var(--mainBlue)" />,
        },
      ]?.map((v, i) => {
        return (
          <Col key={i} xs={24} sm={12} md={6}>
            <div className="analytic_box">
              {v?.icon}
              <p>{v?.name}</p>
              {loading ? (
                <div>
                  <Spin />
                </div>
              ) : (
                <p style={{ fontSize: "18px" }}>
                  <strong>{v?.total}</strong>
                </p>
              )}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default Analytic;
