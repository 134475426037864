import React, { useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Radio,
} from "antd";

import { useAppSelector } from "../../store/hooks";
import { createNotification } from "../../store/services/notification";

import { getLabels } from "../../store/services/label";
import TextArea from "antd/es/input/TextArea";

interface Props {
  getData: () => void;
}

const CreateNotification: React.FC<Props> = ({ getData }) => {
  const _general: "general" = "general";
  const _specific: "specific" = "specific";
  const { labels } = useAppSelector((state) => state.label);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [notificationType, setNotificationType] = useState<string>(_general);


  const showModal = () => {
    setIsModalOpen(true);
    _getLabels();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const _getLabels = async () => {
    setLoading("label");
    await getLabels();
    setLoading("");
  };

  const onFinish = async (values: any) => {
    const obj = {
      title: values?.title,
      notificationType: notificationType,
      users:
        labels
          ?.find((v) => v?._id === values?.labelId)
          ?.users?.map((v) => v?._id) || [],
      type: values?.type,
      message: values?.message,
      subject: values?.subject,
      labelId: values.labelId,
    };
    await _create(obj);
  };

  const _create = async (payload: any) => {
    setLoading("submit");
    const res = await createNotification(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setLoading("");
  };

  return (
    <>
      <Button onClick={showModal}>Create Notification</Button>
      <Modal
        forceRender
        title={"Create Notification"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            e.notificationType && setNotificationType(e.notificationType);
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Notification Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Title (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Notification Type"
                name="notificationType"
              >
                <Radio.Group defaultValue={notificationType}>
                  <Radio value={_general}>General</Radio>
                  <Radio value={_specific}>Specific</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {notificationType === _specific ? (
              <>
                <Col xs={24}>
                  <Form.Item
                    label="User Group"
                    name="labelId"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      loading={loading === "label"}
                      optionFilterProp="children"
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size={"large"}
                      maxTagCount={"responsive"}
                      placeholder="Select"
                      className="users_select_box"
                      options={labels?.map((v) => {
                        return {
                          label: v?.name,
                          value: v?._id,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Notification Type (Required)"
                      options={[
                        { value: "badge", label: "Badge" },
                        { value: "points", label: "Points" },
                        { value: "order", label: "Order" },
                        { value: "profile", label: "Profile" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col xs={24}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  placeholder="Notification Subject (Required)"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  placeholder="Notification Message (Required)"
                />
              </Form.Item>
            </Col>
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {"Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateNotification;
