import { createSlice } from "@reduxjs/toolkit";

import { DashboardState } from "../interfaces/Dashboard";

const initialState: DashboardState = {
  loading: false,
  analytics: {
    totalUsers: 0,
    totalProducts: 0,
    totalCarts: 0,
    totalWishlists: 0,
    totalOrders: 0,
    totalGroupWishlists: 0,
  },
  bestProducts: [],
  topUsers: [],
  countByStatus: [],
  salesData: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDashboardAnalytic: (state, action) => {
      state.analytics = action.payload.analytics;
    },
    setBestProducts: (state, action) => {
      state.bestProducts = action.payload;
    },
    setTopUsers: (state, action) => {
      state.topUsers = action.payload;
    },
    setCountByStatus: (state, action) => {
      state.countByStatus = action.payload;
    },
    setSalesData: (state, action) => {
      state.salesData = action.payload;
    },
  },
});

export const {
  setDashboardAnalytic,
  setLoading,
  setBestProducts,
  setTopUsers,
  setCountByStatus,
  setSalesData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
