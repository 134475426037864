import React from "react";

import TopProducts from "./TopProducts";
import Analytic from "./Analytic";
import TopUsers from "./TopUsers";
import ChartSection from "./ChartSection";

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard_container">
      <Analytic />
      <ChartSection />
      <TopProducts />
      <TopUsers />
    </div>
  );
};

export default Dashboard;
