import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import { RewardType } from "../../../store/interfaces/Reward";
import { firstLetterCap } from "../../../utils";
import { getRewards } from "../../../store/services/reward";
import CreateReward from "../../../components/Modals/CreateReward";

const { Search } = Input;

const Reward: React.FC = () => {
  const { isLoading, rewards } = useAppSelector((state) => state.reward);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, [page]);

  const getData = async () => {
    await getRewards();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      render: (t: string) => <p>{t?.toLocaleString()}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (t: string) => <p>${t}</p>,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: RewardType) => (
        <div className="icon_box">
          <CreateReward isEdit editObj={obj} getData={getData} />
          <DeleteModal type={"reward"} id={obj?._id} getData={getData} />
        </div>
      ),
    },
  ];

  const filteredRewards = rewards?.filter((v) =>
    v?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  return (
    <div className="user_container">
      <header>
        <h1>Rewards</h1>
        <div>
          <CreateReward getData={getData} />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={filteredRewards}
          rowKey={(record) => record._id}
          scroll={{ x: 650 }}
          pagination={{
            current: page,
            total: rewards?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Reward;
