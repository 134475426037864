import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { WishlistState } from "../interfaces/Wishlist";

const initialState: WishlistState = {
  isLoading: true,
  total: 0,
  wishlists: [],
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setWishlist: (state, action) => {
      state.total = action.payload.total;
      state.wishlists = action.payload.wishlists;
    },
  },
});

export const { setLoading, setWishlist } = wishlistSlice.actions;

export const getWishlists = (state: RootState) => state.wishlist.wishlists;

export default wishlistSlice.reducer;
