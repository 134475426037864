import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { ProductState } from "../interfaces/Product";

const initialState: ProductState = {
  isLoading: true,
  total: 0,
  products: [],
  allProducts: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProducts: (state, action) => {
      state.total = action.payload.total;
      state.products = action.payload.products;
    },
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
  },
});

export const { setProducts, setLoading, setAllProducts } = productSlice.actions;

export const getProducts = (state: RootState) => state.product.products;

export default productSlice.reducer;
