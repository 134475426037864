import React, { useEffect, useState } from "react";
import { Button, Input, Table } from "antd";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

import { getUsers, getUserCsv } from "../../../store/services/user";

import { UserType } from "../../../store/interfaces/User";

import { TbEdit } from "react-icons/tb";

import DeleteModal from "../../../components/Modals/DeleteModal";
import { ViewUser } from "../../../components/Modals/ViewUser";
import UploadUserCsv from "../../../components/Modals/UploadUserCsv";

const { Search } = Input;

const User: React.FC = () => {
  const { isLoading, users, total } = useAppSelector((state) => state.user);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [csvLoading, setCSVLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(search);
    // eslint-disable-next-line
  }, [page]);

  const getData = async (search?: string) => {
    await getUsers({
      search: search || "",
      page,
      perPage,
    });
  };

  const downloadCsv = async () => {
    setCSVLoading(true);
    const response: any = await getUserCsv();

    if (response?.data?.length > 0) {
      const blob = new Blob([response?.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded_file.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setCSVLoading(false);
    }
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (t: string) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              t ? t : require("../../../assets/images/user-icon.jpg")
            })`,
          }}
        ></div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_: string, obj: UserType) => (
        <p>
          {obj?.firstName} {obj?.lastName}
        </p>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      render: (t: string) => <p>{t ? Number(t).toFixed(2) : 0}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (t: string) => <p>{t ? t : "-"}</p>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (t: string) => (
        <p style={{ color: t ? "var(--mainGreen)" : "var(--mainRed)" }}>
          {t ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: UserType) => (
        <div className="icon_box">
          <ViewUser data={obj} />
          <TbEdit
            className="icon"
            onClick={() =>
              navigate("/create-user", {
                state: {
                  isEdit: true,
                  user: obj,
                },
              })
            }
          />
          <DeleteModal type={"user"} id={obj?._id} getData={getData} />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Users</h1>
        <div>
          <UploadUserCsv getData={getData} />
          <Button onClick={() => navigate("/create-user")}>Create User</Button>
          <Button loading={csvLoading} onClick={downloadCsv}>
            Download CSV
          </Button>
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          onSearch={(value) => getData(value)}
          enterButton
          allowClear
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            value === "" && getData("");
          }}
        />
      </div>

      <div className="user_table_container">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={users}
          rowKey={(record) => record._id}
          scroll={{ x: 1000 }}
          pagination={{
            current: page,
            total: total,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default User;
