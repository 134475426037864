import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider, Form, Input, Avatar } from "antd";

import { TbEdit } from "react-icons/tb";

import { CreateReviewType, ReviewType } from "../../store/interfaces/Review";
import { createReview, updateReview } from "../../store/services/review";

const { TextArea } = Input;

interface Props {
  isEdit?: boolean;
  editObj?: ReviewType;
  getData: () => void;
}

const CreateReview: React.FC<Props> = ({ isEdit, editObj }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const showModal = () => {
    setIsModalOpen(true);
    if (isEdit) {
      form.setFieldsValue({
        imageUrl: editObj?.imageUrl,
        name: editObj?.name,
        rating: editObj?.rating,
        comments: editObj?.comments,
      });
      editObj?.imageUrl && setImageSrc(editObj?.imageUrl);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFile(null);
    setImageSrc(null);
    form.resetFields();
  };

  const onFinish = async (values: CreateReviewType) => {
    if (file) {
      let formdata = new FormData();
      formdata.append("_id", editObj?._id || "");
      formdata.append("name", values?.name || "");
      formdata.append("rating", values?.rating || "");
      formdata.append("comments", values?.comments || "");
      formdata.append("storecsv", file || "");
      isEdit ? await _updateReview(formdata) : await _createReview(formdata);
    } else {
      const obj = {
        _id: editObj?._id || "",
        imageUrl: values?.imageUrl,
        name: values?.name,
        rating: values?.rating,
        comments: values?.comments,
      };
      isEdit ? await _updateReview(obj) : await _createReview(obj);
    }
  };

  const _createReview = async (payload: CreateReviewType | any) => {
    setLoading("submit");
    const res = await createReview(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const _updateReview = async (payload: CreateReviewType | any) => {
    setLoading("submit");
    const res = await updateReview(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const handleFile = (file: any) => {
    setFile(file);
    const src: string = URL.createObjectURL(file);
    setImageSrc(src);
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={showModal}>Create Review</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Review" : "Create Review"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Avatar
                  size={55}
                  src={
                    imageSrc
                      ? imageSrc
                      : require("../../assets/images/cart.png")
                  }
                />
                <Form.Item
                  name={"imageUrl"}
                  label="Photo"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <>
                    <label className="file_button" htmlFor="fileInput">
                      <input
                        type="file"
                        hidden
                        id="fileInput"
                        onChange={(e: any) => {
                          handleFile(e?.target?.files[0]);
                        }}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"name"}
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"rating"}
                label="Rating"
                rules={[
                  {
                    validator: (rule, value) => {
                      const rating = parseFloat(value);
                      if (!value) {
                        return Promise.reject("Required");
                      } else if (isNaN(rating) || rating < 1 || rating > 5) {
                        return Promise.reject("Rating must be between 1 and 5");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Rating (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"comments"}
                label="Comments"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Comments (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateReview;
