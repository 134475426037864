import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import PrivateWrapper from "./PrivateRoutes";
import AuthWrapper from "./AuthRoutes";

import { Login } from "../pages/Auth";
import {
  Dashboard,
  User,
  CreateUser,
  Product,
  CreateProduct,
  Cart,
  Wishlist,
  Order,
  Category,
  Reward,
  Schedule,
  Account,
  Page,
  Review,
  Settings,
  GroupWishlist,
  Label,
  Badge,
  NotificationPage,
  Notifications,
} from "../pages/App";

const Routers: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateWrapper />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/user" element={<User />} />
          <Route path="/create-user" element={<CreateUser />} />
          <Route path="/product" element={<Product />} />
          <Route path="/create-product" element={<CreateProduct />} />
          <Route path="/order" element={<Order />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/category" element={<Category />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/account" element={<Account />} />
          <Route path="/page" element={<Page />} />
          <Route path="/review" element={<Review />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/group-wishlist" element={<GroupWishlist />} />
          <Route path="/label" element={<Label />} />
          <Route path="/badge" element={<Badge />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path="/view-notifications" element={<Notifications />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
