import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import {
  CreateDiscountPayload,
  GetDiscountPayload,
} from "../interfaces/Discount";
import { setLoading, setDiscounts } from "../slices/discountSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Discount Created Successfully";
const Updated = "Discount Updated Successfully";
const Deleted = "Discount Deleted Successfully";

export const createDiscount = async (payload: CreateDiscountPayload) => {
  try {
    attachToken();
    const response = await privateAPI.post("/discount/create", payload);
    notification.success({
      message: response?.data?.data?.message || Created,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getDiscounts = async (payload: GetDiscountPayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/discount/get-all", {
      params: payload,
    });
    if (response) {
      store.dispatch(setDiscounts(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getAllDiscounts = async () => {
  try {
    attachToken();
    const response = await privateAPI.get("/discount/get-all-discounts");
    if (response) {
      store.dispatch(setDiscounts(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getDiscountOne = async (id: string) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get(`/discount/get-one?discountId=${id}`);
    if (response) {
      store.dispatch(setDiscounts(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateDiscount = async (payload: CreateDiscountPayload) => {
  try {
    attachToken();
    const response = await privateAPI.put("/discount/update", payload);
    notification.success({
      message: response?.data?.data?.message || Updated,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteDiscount = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.delete(
      `/discount/delete?discountId=${id}`
    );
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
