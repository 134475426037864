import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";
import { CategoryState } from "../interfaces/Category";

const initialState: CategoryState = {
  isLoading: true,
  total: 0,
  allCategories: [],
  typeCategories: [],
  subCategories: [],
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCategory: (state, action) => {
      state.allCategories = action.payload;
    },
    setTypeCategories: (state, action) => {
      state.typeCategories = action.payload;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
  },
});

export const { setCategory, setLoading, setTypeCategories, setSubCategories } =
  categorySlice.actions;

export const getCategoriesByType = (state: RootState) =>
  state.category.allCategories;

export default categorySlice.reducer;
