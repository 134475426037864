import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

import { useAppSelector } from "../../../store/hooks";

// import DeleteModal from "../../../components/Modals/DeleteModal";

import { firstLetterCap } from "../../../utils";
import { DiscountType } from "../../../store/interfaces/Discount";
import { getDiscounts } from "../../../store/services/discount";

import moment from "moment";
import CreateDiscount from "../../../components/Modals/CreateDiscount";

const { Search } = Input;

const Discount: React.FC = () => {
  const { isLoading, discounts, total } = useAppSelector(
    (state) => state.discount
  );
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(search);
    // eslint-disable-next-line
  }, [page]);

  const getData = async (search?: string) => {
    await getDiscounts({
      search: search || "",
      page,
      perPage,
    });
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (t: string) => <p>{t?.toUpperCase()}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "desc",
    },
    {
      title: "Type",
      dataIndex: "discountType",
      key: "discountType",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Points",
      dataIndex: "discountValue",
      key: "discountValue",
      render: (t: string, obj: DiscountType) => (
        <p>{obj?.discountType === "percentage" ? `${t}%` : t}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (t: string) => (
        <p style={{ color: t ? "var(--mainGreen)" : "var(--mainRed)" }}>
          {t ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 120,
      render: (t: Date) => <p>{moment(t).format("MMM DD, YYYY")}</p>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 120,
      render: (t: Date) => <p>{moment(t).format("MMM DD, YYYY")}</p>,
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: DiscountType) => (
        <div className="icon_box">
          <CreateDiscount isEdit={true} editObj={obj} getData={getData} />
          {/* <DeleteModal type={"discount"} id={obj?._id} getData={getData} /> */}
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Discounts</h1>
        <CreateDiscount getData={getData} />
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          onSearch={(value) => getData(value)}
          enterButton
          allowClear
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            value === "" && getData("");
          }}
        />
      </div>

      <div className="user_table_container">
        <Table
          loading={isLoading}
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={discounts}
          scroll={{ x: 1100 }}
          pagination={{
            current: page,
            total: total,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Discount;
