import React, { useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Upload,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadProductCsv } from "../../store/services/product";

interface Props {
  getData: () => void;
}

const UploadProductCsv: React.FC<Props> = ({ getData }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadCsv, setUploadCsv] = useState<any>(null);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
    setUploadCsv(null);
  };

  const onFinish = async () => {
    if (uploadCsv?.length > 0) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("storecsv", uploadCsv[0]);
      const response = await uploadProductCsv(formdata);
      if (response) {
        handleCancel();
        getData();
      }
      setLoading(false);
    } else {
      notification.error({
        message: "Please upload csv",
      });
    }
  };

  const handleDownload = async () => {
    const fileName = "Product_Example_Csv.csv";
    const response = await fetch("/oca-web-app.products.csv");
    const csvContent = await response.text();

    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <Button onClick={showModal}>{`Upload Product CSV`}</Button>
      <Modal
        forceRender
        title={`Upload Product Csv`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item label="">
                <Upload
                  fileList={uploadCsv}
                  beforeUpload={(file) => {
                    setUploadCsv([file]);
                    return false;
                  }}
                  onRemove={() => {
                    setUploadCsv(null);
                  }}
                  style={{ width: "100%" }}
                >
                  <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                    Upload Csv
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <p>
                Download a{" "}
                <strong className="sample_csv_text" onClick={handleDownload}>
                  sample CSV template
                </strong>{" "}
                to see an example of the format required.
              </p>
            </Col>
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button loading={loading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default UploadProductCsv;
