import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider, Form, Input } from "antd";

import { TbEdit } from "react-icons/tb";

import { CreateRewardType, RewardType } from "../../store/interfaces/Reward";
import { createRewards, updateReward } from "../../store/services/reward";

interface Props {
  isEdit?: boolean;
  editObj?: RewardType;
  getData: () => void;
}

const CreateReward: React.FC<Props> = ({ isEdit, editObj, getData }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);

    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        points: editObj?.points,
        price: editObj?.price,
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = async (values: CreateRewardType) => {
    const name = values?.name ? values?.name : editObj?.name || "";
    const points = values?.points ? values?.points : editObj?.points || "";
    const price = values?.price ? values?.price : editObj?.price || "";

    const obj = {
      _id: editObj?._id ? editObj?._id : null,
      name,
      points: Number(points),
      price: Number(price),
    };
    isEdit ? await _updateReward(obj) : await _createReward(obj);
  };

  const _createReward = async (payload: CreateRewardType) => {
    setLoading(true);
    const res = await createRewards(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setLoading(false);
  };

  const _updateReward = async (payload: CreateRewardType) => {
    setLoading(true);
    const res = await updateReward(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setLoading(false);
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={showModal}>Create Reward</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Reward" : "Create Reward"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Points"
                name="points"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input type="number" placeholder="Points (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input type="number" placeholder="Price (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button loading={loading} type="primary" htmlType="submit">
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateReward;
