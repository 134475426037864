import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider } from "antd";

import { FaRegEye } from "react-icons/fa";

import { GroupWishlistType } from "../../store/interfaces/GroupWishlist";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

interface Props {
  data: GroupWishlistType;
}

const ViewGroupWishlist: React.FC<Props> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getsizeAndColor = () => {
    const variations = data?.product?.productId?.variations;
    const size = data?.product?.size;
    const color = data?.product?.color;
    const points = data?.product?.productId?.points;

    let obj: any = {
      size: {
        name: "-",
      },
      color: {
        hex: "-",
      },
      points: 0,
    };
    if (variations?.length > 0) {
      const isSize = variations?.find((v) => v?._id === size);
      const isColor = isSize?.colors?.find((v) => v?._id === color);
      obj = {
        size: isSize,
        color: isColor,
        points: isColor?.points,
      };
    } else {
      obj = {
        size: "-",
        color: "-",
        points: points,
      };
    }
    return obj;
  };

  const getTotalPoints = () => {
    const points = getsizeAndColor()?.points;
    return (
      Number(points) +
      Number(data?.product?.productId?.deliveryFee || 0) +
      Number(data?.deliveryFee || 0)
    );
  };

  return (
    <>
      <FaRegEye className="icon" onClick={showModal} />
      <Modal
        forceRender
        title={"View Group Wishlist"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="view_user_modal"
        centered
      >
        <Divider />
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <h3>Product</h3>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Product Name:</p>
              <p>
                <strong>{data?.product?.productId?.name}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Size:</p>
              <p>
                <strong>{getsizeAndColor()?.size?.size}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Color:</p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {getsizeAndColor()?.color?.hex && (
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: getsizeAndColor()?.color?.hex,
                    }}
                  />
                )}
                <p>
                  <strong>{getsizeAndColor()?.color?.hex}</strong>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Initial Points:</p>
              <p>
                <strong>{data?.initialPoints}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Points:</p>
              <p>
                <strong>{getsizeAndColor()?.color?.points}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Product Delivery:</p>
              <p>
                <strong>{data?.product?.productId?.deliveryFee || 0}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Global Delivery:</p>
              <p>
                <strong>
                  {data?.deliveryFee ? data?.deliveryFee : 0 || 0}
                </strong>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div>
              <p>Total Points:</p>
              <p>
                <strong>{getTotalPoints()}</strong>
              </p>
            </div>
          </Col>
          <Col xs={24}>
            <h3>Users ({data?.users?.length})</h3>
          </Col>
          {data?.users?.map((v, i) => {
            return (
              <>
                <Col xs={24} sm={8}>
                  <p>
                    {i + 1}) {v?.firstName} {v?.lastName}
                  </p>
                </Col>
                <Col xs={24} sm={16}>
                  <p>{v?.email}</p>
                </Col>
              </>
            );
          })}
          <Col xs={24}>
            <h3>Contributed ({data?.contributed?.length})</h3>
          </Col>
          {data?.contributed?.length > 0 ? (
            data?.contributed?.map((v, i) => {
              return (
                <>
                  <Col xs={24} sm={8}>
                    <p>
                      {i + 1}) {v?.firstName} {v?.lastName}
                    </p>
                  </Col>
                  <Col xs={24} sm={16}>
                    <p>
                      {v?.email}{" "}
                      <IoCheckmarkDoneCircleSharp
                        color="var(--mainGreen)"
                        style={{ marginBottom: "-2px" }}
                      />
                    </p>
                  </Col>
                </>
              );
            })
          ) : (
            <Col xs={24}>
              <p>No Contributers</p>
            </Col>
          )}

          <Col xs={24}>
            <div className="view_product_footer">
              <Button className="buttonOutlined" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ViewGroupWishlist;
