import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/Layout";

const PrivateWrapper: React.FC = () => {
  const isAuthenticated = localStorage.getItem("token");

  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateWrapper;
