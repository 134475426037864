import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

// components
import EditPage from "./EditPage";

const Page: React.FC = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Landing Page",
      children: <EditPage title={"landing"} />,
    },
    // {
    //   key: "2",
    //   label: "Page 2",
    //   children: <EditPage content={'Page 2'}/>,
    // },
    // {
    //   key: "3",
    //   label: "Page 3",
    //   children: <EditPage content={'Page 3'}/>,
    // },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Banners</h1>
      </header>
      <div className="tabs_container" style={{ marginTop: "20px" }}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default Page;
