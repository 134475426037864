import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider, Carousel } from "antd";

import { FaRegEye } from "react-icons/fa";

import { CartProductType } from "../../store/interfaces/Cart";
import { firstLetterCap } from "../../utils";

interface Props {
  data: CartProductType;
}

const ViewCartDetails: React.FC<Props> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getPoints = (data: CartProductType) => {
    if (data?.product?.variations?.length > 0) {
      let points = 0;
      data?.product?.variations?.forEach((v) => {
        v?.colors?.forEach((x) => {
          if (x?.hex === data?.color) {
            points = Number(data?.points);
          }
        });
      });
      return points;
    } else {
      return data?.product?.points;
    }
  };

  return (
    <>
      <FaRegEye className="icon" onClick={showModal} />
      <Modal
        forceRender
        title={"View Product"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="view_product_modal"
        centered
      >
        <Divider />
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Carousel autoplay autoplaySpeed={3000}>
              {data?.product?.imagesUrl?.map((v) => {
                return (
                  <div key={v} className="carousel_images">
                    <img src={v} alt={"Product"} />
                  </div>
                );
              })}
            </Carousel>
          </Col>
          <Col xs={24} md={12}>
            <div className="detail_box">
              <h2>{data?.product?.name}</h2>
              <hr />
              <p>
                Description: <b>{data?.product?.description}</b>
              </p>
              <hr />
              <p>
                Category: <b>{firstLetterCap(data?.product?.category?.name)}</b>
              </p>
              <hr />
              <p>
                Sub Category:{" "}
                <b>{firstLetterCap(data?.product?.subCategory?.name)}</b>
              </p>
              <hr />
              <p>
                Quantity: <b>{Number(data?.quantity)}</b>
              </p>
              <hr />
              {data?.size && (
                <>
                  <p>
                    Size: <b>{data?.size}</b>
                  </p>
                  <hr />
                </>
              )}
              {data?.color && (
                <>
                  <p>
                    Color: &nbsp;
                    <span
                      style={{
                        width: "60px",
                        height: "20px",
                        borderRadius: "3px",
                        backgroundColor: data?.color,
                        color: data?.color,
                      }}
                    >
                      {data?.color}
                    </span>
                  </p>
                  <hr />
                </>
              )}
              <p>
                Points: <b>{getPoints(data)}</b>
              </p>
              <hr />
              <p>
                Total Points: <b>{getPoints(data) * Number(data?.quantity)}</b>
              </p>
              <hr />
            </div>
          </Col>

          <Col xs={24}>
            <div className="view_product_footer">
              <Button className="buttonOutlined" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ViewCartDetails;
