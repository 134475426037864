import React, { useEffect } from "react";
import { Spin, Tabs } from "antd";
import type { TabsProps } from "antd";

import DevliveryFeeTab from "./DeliverFeeTab";
import { getSettings } from "../../../store/services/settings";
import { useAppSelector } from "../../../store/hooks";

const Settings: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.settings);

  useEffect(() => {
    _getSettings();
  }, []);

  const _getSettings = async () => await getSettings();

  const onChange = (key: string) => console.log(key);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: <DevliveryFeeTab />,
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Settings</h1>
      </header>
      <div className="tabs_container" style={{ marginTop: "20px" }}>
        {isLoading ? (
          <div className="spin_loader">
            <Spin />
          </div>
        ) : (
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        )}
      </div>
    </div>
  );
};

export default Settings;
