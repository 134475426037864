import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider, Form, Input, Avatar, Checkbox } from "antd";

import { TbEdit } from "react-icons/tb";

import { BadgeType, CreateBadgeType } from "../../store/interfaces/Badge";
import { createBadge, updateBadge } from "../../store/services/badge";

const { TextArea } = Input;

interface Props {
  isEdit?: boolean;
  editObj?: BadgeType;
}

const CreateBadge: React.FC<Props> = ({ isEdit, editObj }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const showModal = () => {
    setIsModalOpen(true);
    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        points: editObj?.points,
        description: editObj?.description,
        isActive: editObj?.isActive,
        imageUrl: editObj?.imageUrl,
      });
      editObj?.imageUrl && setImageSrc(editObj?.imageUrl);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setImageSrc(null);
  };

  const onFinish = async (values: CreateBadgeType) => {
    if (file) {
      let formdata = new FormData();
      formdata.append("_id", editObj?._id || "");
      formdata.append("name", values?.name || "");
      formdata.append("points", String(values?.points));
      formdata.append("isActive", String(values?.isActive));
      formdata.append("description", values?.description);
      formdata.append("storecsv", file || "");
      isEdit ? await _update(formdata) : await _create(formdata);
    } else {
      const obj = {
        _id: editObj?._id ? editObj?._id : null,
        name: values?.name,
        points: values?.points,
        isActive: values?.isActive,
        description: values?.description,
        imageUrl: values?.imageUrl,
      };
      isEdit ? await _update(obj) : await _create(obj);
    }
  };

  const _create = async (payload: CreateBadgeType | any) => {
    setLoading("submit");
    const res = await createBadge(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const _update = async (payload: CreateBadgeType | any) => {
    setLoading("submit");
    const res = await updateBadge(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const handleFile = (file: any) => {
    setFile(file);
    const src: string = URL.createObjectURL(file);
    setImageSrc(src);
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={showModal}>Create Badge</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Badge " : "Create Badge"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Avatar
                  size={55}
                  src={
                    imageSrc
                      ? imageSrc
                      : require("../../assets/images/badge.png")
                  }
                />
                <Form.Item
                  name={"imageUrl"}
                  label="Photo"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <>
                    <label className="file_button" htmlFor="fileInput">
                      <input
                        type="file"
                        hidden
                        id="fileInput"
                        onChange={(e: any) => {
                          handleFile(e?.target?.files[0]);
                        }}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </>
                </Form.Item>
              </div>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder="Name (Up to 20 characters)"
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Points"
                name="points"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input min={1} type="number" placeholder="Points (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                name={"description"}
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Description (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"isActive"}
                label=""
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>Active</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateBadge;
