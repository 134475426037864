import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { setGroupWishlist, setLoading } from "../slices/groupWishlistSlice";
import {
  CreateGroupWishlistType,
  GetGroupWishlistPayload,
  UpdateStatusType,
} from "../interfaces/GroupWishlist";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Group Wishlist Created Successfully";
// const Updated = "Group Wishlist Updated Successfully";
const Deleted = "Group Wishlist Deleted Successfully";

export const createGroupWishlists = async (
  payload: CreateGroupWishlistType
) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/groupwishlist/create", payload);
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      getGroupWishlists();
    }
    return response;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getGroupWishlists = async () => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/groupwishlist/get-all-wishlist");
    if (response) {
      store.dispatch(setGroupWishlist(response?.data?.data));
    }
    return response;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteGroupWishlists = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/groupwishlist/delete`, {
      _id: id,
    });
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
      getGroupWishlists();
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateWishlistStatus = async (payload: UpdateStatusType) => {
  try {
    attachToken();
    const response = await privateAPI.post(
      "/groupwishlist/update-status",
      payload
    );
    if (response) {
      getGroupWishlists();
    }
    return response;
  } catch (error: any) {
    console.log(
      "Error update status:",
      error?.response?.data?.message?.message
    );
    notification.error({
      message: error?.response?.data?.message?.message || "Server error",
    });
  }
};
