import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { GetCartPayload } from "../interfaces/Cart";
import { setCarts, setLoading } from "../slices/cartSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

// const Created = "Cart Created Successfully";
// const Updated = "Cart Updated Successfully";
const Deleted = "Cart Deleted Successfully";

export const getCarts = async (payload: GetCartPayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/cart/get-all-cart", {
      params: payload,
    });
    if (response) {
      store.dispatch(setCarts(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteAllCart = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/cart/delete`, {
      _id: id,
    });
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteOneCart = async (id: string, productId?: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/cart/delete-cart-product`, {
      _id: id,
      productId,
    });
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
