import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { CreateLabelType } from "../interfaces/Label";
import { setLoading, setLabels } from "../slices/labelSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Label Created Successfully";
const Updated = "Label Updated Successfully";
const Deleted = "Label Deleted Successfully";

export const createLabel = async (payload: CreateLabelType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/label/create", payload);
    if (response) {
      await getLabels();
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getLabels = async () => {
  try {
    store.dispatch(setLoading(true));
    attachToken();
    const response = await privateAPI.get("/label/get");
    if (response) {
      store.dispatch(setLabels(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateLabel = async (payload: CreateLabelType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.put("/label/update", payload);
    if (response) {
      await getLabels();
      notification.success({
        message: response?.data?.data?.message || Updated,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteLabel = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/label/delete`, { _id: id });
    if (response) {
      await getLabels();
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
