import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Login as LoginInterface } from "../../../store/interfaces/Auth";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { adminLogin } from "../../../store/services/auth";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onFinish = async (values: LoginInterface) => {
    setLoading(true);
    await adminLogin(values);
    navigate("/");
    setLoading(false);
  };

  return (
    <div className="auth_container">
      <div className="auth_bg" />
      <h1>OCA Reward and Shopping Mall</h1>
      <div className="form_wrapper">
        <h2 style={{ marginBottom: "20px" }}>Login</h2>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible: Boolean) =>
                visible ? (
                  <IoIosEye size={20} style={{ cursor: "pointer" }} />
                ) : (
                  <IoIosEyeOff size={20} style={{ cursor: "pointer" }} />
                )
              }
            />
          </Form.Item>
          <Button
            className="button"
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            LOGIN
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
