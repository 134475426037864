import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { GetWishlistPayload } from "../interfaces/Wishlist";
import { setWishlist, setLoading } from "../slices/wishlistSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

// const Created = "Wishlist Created Successfully";
// const Updated = "Wishlist Updated Successfully";
const Deleted = "Wishlist Deleted Successfully";

export const getWishlists = async (payload: GetWishlistPayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/wishlist/get-all-wishlist", {
      params: payload,
    });
    if (response) {
      store.dispatch(setWishlist(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteAllWishlist = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/wishlist/delete`, {
      _id: id,
    });
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteOneWishlist = async (id: string, productId?: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(
      `/wishlist/delete-wishlist-product`,
      {
        _id: id,
        productId,
      }
    );
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
