import React, { useState } from "react";

import { Modal, Button } from "antd";

import { HiOutlineTrash } from "react-icons/hi";
import { PiWarningCircleLight } from "react-icons/pi";

import { deleteUser } from "../../store/services/user";
import { deleteProduct } from "../../store/services/product";
import {
  deleteAllWishlist,
  deleteOneWishlist,
} from "../../store/services/wishlist";
import { deleteDiscount } from "../../store/services/discount";
import { deleteCategory } from "../../store/services/category";
import { deleteReward } from "../../store/services/reward";
import { deleteAllCart, deleteOneCart } from "../../store/services/cart";
import { deleteSchedule } from "../../store/services/schedule";
import { deleteReview } from "../../store/services/review";
import { deleteGroupWishlists } from "../../store/services/groupWishlist";
import { deleteLabel } from "../../store/services/label";
import { deleteBadge } from "../../store/services/badge";
import { deleteNotification } from "../../store/services/notification";

interface Props {
  id: string;
  productId?: string;
  type: string;
  getData: () => void;
}

const DeleteModal: React.FC<Props> = ({ id, type, getData, productId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const res =
      type === "user"
        ? await deleteUser(id)
        : type === "product"
        ? await deleteProduct(id)
        : type === "cart-all"
        ? await deleteAllCart(id)
        : type === "cart-one"
        ? await deleteOneCart(id, productId)
        : type === "wishlist-all"
        ? await deleteAllWishlist(id)
        : type === "wishlist-one"
        ? await deleteOneWishlist(id, productId)
        : type === "discount"
        ? await deleteDiscount(id)
        : type === "category"
        ? await deleteCategory(id)
        : type === "reward"
        ? await deleteReward(id)
        : type === "schedule"
        ? await deleteSchedule(id)
        : type === "review"
        ? await deleteReview(id)
        : type === "group-wishlist"
        ? await deleteGroupWishlists(id)
        : type === "label"
        ? await deleteLabel(id)
        : type === "badge"
        ? await deleteBadge(id)
        : type === "notifications"
        ? await deleteNotification(id)
        : "";
    if (res) {
      handleCancel();
      getData();
    }
    setIsLoading(false);
  };

  return (
    <>
      <HiOutlineTrash className="icon" onClick={showModal} />
      <Modal
        forceRender
        title={" "}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="delete_modal"
        centered
      >
        <div className="delete_modal_wrapper">
          <PiWarningCircleLight size={60} color={"orange"} />
          <h2>Are You Sure?</h2>
          <div className="delete_modal_footer">
            <Button className="buttonOutlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="button"
              type="primary"
              loading={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DeleteModal;
