import { createSlice } from "@reduxjs/toolkit";
import { NotificationGroupStateType } from "../interfaces/Notification";

const initialState: NotificationGroupStateType = {
  loading: false,
  notificationsGroup: [],
};

export const notificationSlice = createSlice({
    name: "notificationsSettings",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setNotificationsGroup(state, action) {
          state.notificationsGroup = action?.payload;
        },
    }
})

export const { setLoading, setNotificationsGroup } = notificationSlice.actions;

export default notificationSlice.reducer;