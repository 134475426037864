import React, { ReactNode } from "react";

import VerticalLayout from "./VerticalLayout";
import MobileLayout from "./MobileLayout";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <MobileLayout />
      <VerticalLayout />
    </>
  );
};

export default Layout;
