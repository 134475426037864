import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";

import { DiscountState } from "../interfaces/Discount";

const initialState: DiscountState = {
  isLoading: true,
  total: 0,
  discounts: [],
};

export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDiscounts: (state, action) => {
      state.total = action.payload.total;
      state.discounts = action.payload.discounts;
    },
  },
});

export const { setLoading, setDiscounts } = discountSlice.actions;

export const getDiscounts = (state: RootState) => state.discount.discounts;

export default discountSlice.reducer;
