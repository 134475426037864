import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateBadge from "../../../components/Modals/CreateBadge";

import { firstLetterCap } from "../../../utils";

import { useAppSelector } from "../../../store/hooks";

import { getBadges } from "../../../store/services/badge";
import { BadgeType } from "../../../store/interfaces/Badge";

import moment from "moment";

const { Search } = Input;

const Badge: React.FC = () => {
  const { isLoading, badges } = useAppSelector((state) => state.badge);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, [page]);

  const getData = async () => {
    await getBadges();
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (t: string) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              t ? t : require("../../../assets/images/badge.png")
            })`,
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (t: string) => (
        <p style={{ color: t ? "var(--mainGreen)" : "var(--mainRed)" }}>
          {t ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: BadgeType) => (
        <div className="icon_box">
          <CreateBadge isEdit editObj={obj} />
          <DeleteModal type={"badge"} id={obj?._id} getData={() => {}} />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Badges</h1>
        <div>
          <CreateBadge />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={badges?.filter((v) =>
            v?.name?.toLowerCase()?.includes(search?.toLowerCase())
          )}
          rowKey={(record) => record._id}
          scroll={{ x: 800 }}
          pagination={{
            current: page,
            total: badges?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Badge;
