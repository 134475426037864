import { useState, useEffect } from "react";
import { Avatar, Button, Col, Form, Input, Row } from "antd";

import { getPage, createBanner } from "../../../store/services/page";

interface Props {
  title: string;
}

const EditPage: React.FC<Props> = () => {
  const [form] = Form.useForm();

  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getPageDetails = async () => {
    const resp = await getPage();
    if (resp) {
      form.setFieldsValue({
        buttonText: resp?.buttonText,
        tag: resp?.tag,
        title: resp?.title,
        url: resp?.url,
      });
      setImageSrc(resp?.image);
    }
  };

  useEffect(() => {
    getPageDetails();
    // eslint-disable-next-line
  }, []);

  const handleFile = (file: any) => {
    setFile(file);
    const src: string = URL.createObjectURL(file);
    setImageSrc(src);
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    let formdata = new FormData();
    formdata.append("title", values?.title);
    formdata.append("tag", values?.tag);
    formdata.append("url", values?.url);
    formdata.append("buttonText", values?.buttonText);
    formdata.append("storecsv", file || "");

    const resp = await createBanner(formdata);

    if (resp) {
      setLoading(false);
    }
  };

  return (
    <div className="create_user_container">
      <div className="form_container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter page title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Tag"
                name="tag"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter tag" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="url"
                label="URL"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter URL" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="buttonText"
                label="Button Text"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter Button Text" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div style={{ width: "100px" }}>
                  <Avatar
                    size={100}
                    src={
                      imageSrc
                        ? imageSrc
                        : require("../../../assets/images/cart.png")
                    }
                  />
                </div>
                <Form.Item name={"imageUrl"} label="Banner">
                  <>
                    <label className="file_button" htmlFor="fileInput">
                      <input
                        type="file"
                        hidden
                        id="fileInput"
                        onChange={(e: any) => {
                          handleFile(e?.target?.files[0]);
                        }}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </>
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default EditPage;
