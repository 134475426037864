import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "../../store";

import { CreateUserPayload, GetUserPayload } from "../interfaces/User";

import { setLoading, setUsers, setUsersSchedule } from "../slices/userSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "User Created Successfully";
const Updated = "User Updated Successfully";
const Deleted = "User Deleted Successfully";
const Uploaded = "User Csv Uploaded Successfully";

export const createUser = async (payload: CreateUserPayload) => {
  try {
    attachToken();
    const response = await privateAPI.post("/admin/create-user", payload);
    notification.success({
      message: response?.data?.data?.message || Created,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getUsers = async (payload: GetUserPayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/admin/get-users", {
      params: payload,
    });
    if (response) {
      store.dispatch(setUsers(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getUsersForSchedule = async () => {
  try {
    attachToken();
    const response = await privateAPI.get("/admin/get-users-schedule");
    if (response) {
      store.dispatch(setUsersSchedule(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateUser = async (payload: CreateUserPayload) => {
  try {
    attachToken();
    const response = await privateAPI.put("/admin/update-user", payload);
    notification.success({
      message: response?.data?.data?.message || Updated,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteUser = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.delete(`/admin/delete-user?userId=${id}`);
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const uploadUserCsv = async (payload: any) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/auth/uploadusers`, payload);
    notification.success({
      message: response?.data?.data?.message || Uploaded,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getUserCsv = async () => {
  try {
    attachToken();
    const response = await privateAPI.get(`/auth/export`);
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
