import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";

export const getPage = async () => {
  try {
    attachToken();
    const response = await privateAPI.get("/settings/get-landing-banner");
    return response?.data?.data;
  } catch (error: any) {
    console.log(
      "Error update status:",
      error?.response?.data?.message?.message
    );
    notification.error({
      message: error?.response?.data?.message?.message || "Server error",
    });
  }
};

export const createBanner = async (payload: any) => {
  try {
    attachToken();
    const response = await privateAPI.post("/settings/create-banner", payload);
    notification.success({
      message: response?.data?.message?.message || "Success",
    });
    return response;
  } catch (error: any) {
    console.log(
      "Error update status:",
      error?.response?.data?.message?.message
    );
    notification.error({
      message: error?.response?.data?.message?.message || "Server error",
    });
  }
};
