import React, { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";

import { ProductType } from "../../../../store/interfaces/Product";
import { getBestSellingProduct } from "../../../../store/services/dashboard";
import { useAppSelector } from "../../../../store/hooks";

import { firstLetterCap } from "../../../../utils";
import ViewProduct from "../../../../components/Modals/ViewProduct";

import type { DatePickerProps } from "antd";
import dayjs from "dayjs";

const TopProducts: React.FC = () => {
  const monthFormat = "YYYY-MM";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const currentDate = `${year}-${month < 10 ? "0" + month : month}`;

  const { bestProducts } = useAppSelector((state) => state.dashboard);
  const [loading, setLoading] = useState<boolean>(true);
  const [date, setDate] = useState<string>(currentDate);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [date]);

  const getData = async () => {
    setLoading(true);
    const _date = date?.split("-");
    await getBestSellingProduct({
      month: Number(_date[1]),
      year: Number(_date[0]),
    });
    setLoading(false);
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: ProductType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.imagesUrl[0]
                ? obj?.imagesUrl[0]
                : require("../../../../assets/images/box.png")
            })`,
          }}
        ></div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (t: string) => (
        <p>{t?.length > 100 ? `${t.slice(0, 150)}...` : t}</p>
      ),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (_: string, obj: ProductType) => (
        <p>{firstLetterCap(obj?.category?.name)}</p>
      ),
    },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (_: string, obj: ProductType) => (
        <p>{firstLetterCap(obj?.subCategory?.name)}</p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: ProductType) => (
        <div className="icon_box">
          <ViewProduct data={obj} />
        </div>
      ),
    },
  ];

  const handleDate: DatePickerProps["onChange"] = (_, dateString) => {
    setDate(dateString);
  };

  return (
    <div className="table_box">
      <header>
        <h1>Best Selling Product</h1>
        <DatePicker
          style={{ width: "150px" }}
          onChange={handleDate}
          picker="month"
          defaultValue={dayjs(date, monthFormat)}
          format={monthFormat}
        />
      </header>
      <Table
        loading={loading}
        columns={columns}
        dataSource={bestProducts}
        rowKey={(record) => record._id}
        scroll={{ x: 1000 }}
        pagination={false}
      />
    </div>
  );
};

export default TopProducts;
