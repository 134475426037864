import React, { useEffect, useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Checkbox,
} from "antd";

import { TbEdit } from "react-icons/tb";

import { getUsersForSchedule } from "../../store/services/user";
import { useAppSelector } from "../../store/hooks";
import { CreateLabelType, LabelType } from "../../store/interfaces/Label";
import { createLabel, updateLabel } from "../../store/services/label";

import { CheckboxChangeEvent } from "antd/es/checkbox";

interface Props {
  isEdit?: boolean;
  editObj?: LabelType;
}

const CreateLabel: React.FC<Props> = ({ isEdit, editObj }) => {
  const [form] = Form.useForm();
  const { scheduleUsers } = useAppSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [userChecked, setUserChecked] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit && editObj?.users?.length === scheduleUsers?.length) {
      setUserChecked(true);
    }
    // eslint-disable-next-line
  }, [scheduleUsers]);

  const showModal = () => {
    setIsModalOpen(true);
    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        users: editObj?.users?.map((v) => v?._id),
      });
    }
    getUsers();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setUserChecked(false);
  };

  const getUsers = async () => {
    setLoading("getUsers");
    await getUsersForSchedule();
    setLoading("");
  };

  const onFinish = async (values: CreateLabelType) => {
    const obj = {
      _id: editObj?._id ? editObj?._id : null,
      name: values?.name,
      users: values?.users,
    };
    isEdit ? await _update(obj) : await _create(obj);
  };

  const _create = async (payload: CreateLabelType) => {
    setLoading("submit");
    const res = await createLabel(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const _update = async (payload: CreateLabelType) => {
    setLoading("submit");
    const res = await updateLabel(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setUserChecked(isChecked);
    if (isChecked) {
      const ids = scheduleUsers?.map((v) => {
        return v?._id;
      });
      form.setFieldValue("users", ids);
    } else {
      form.setFieldValue("users", undefined);
    }
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={showModal}>Create Label</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Label " : "Create Label"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label=""
                name="allUser"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Checkbox checked={userChecked} onChange={handleCheckbox}>
                  Select all users ({scheduleUsers?.length})
                </Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Users"
                name="users"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  loading={loading === "getUsers"}
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  mode="multiple"
                  size={"large"}
                  maxTagCount={"responsive"}
                  placeholder="Select Users"
                  className="users_select_box"
                  options={scheduleUsers?.map((v) => {
                    return {
                      label: v?.email,
                      value: v?._id,
                    };
                  })}
                />
              </Form.Item>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateLabel;
