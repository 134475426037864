import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { getTopUsers } from "../../../../store/services/dashboard";
import { useAppSelector } from "../../../../store/hooks";
import { UserType } from "../../../../store/interfaces/User";
import { ViewUser } from "../../../../components/Modals/ViewUser";

const TopUsers: React.FC = () => {
  const { topUsers } = useAppSelector((state) => state.dashboard);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    await getTopUsers();
    setLoading(false);
  };

  const columns = [
    // {
    //   title: " ",
    //   dataIndex: "imageUrl",
    //   key: "imageUrl",
    //   render: (t: string) => (
    //     <div
    //       className="users_image_box"
    //       style={{
    //         backgroundImage: `url(${
    //           t ? t : require("../../../../assets/images/user-icon.jpg")
    //         })`,
    //       }}
    //     ></div>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_: string, obj: UserType) => (
        <p>
          {obj?.firstName} {obj?.lastName}
        </p>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      render: (t: string) => <p>{t ? Number(t).toFixed(2) : 0}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (t: string) => <p>{t ? t : "-"}</p>,
    },
    {
      title: "Actions",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: UserType) => (
        <div className="icon_box">
          <ViewUser data={obj} />
        </div>
      ),
    },
  ];

  return (
    <div className="table_box">
      <header>
        <h1>Top 10 Users</h1>
      </header>
      <Table
        className="category_table"
        loading={loading}
        columns={columns}
        dataSource={topUsers}
        rowKey={(record: any) => record._id}
        scroll={{ x: 1000 }}
        pagination={false}
      />
    </div>
  );
};

export default TopUsers;
