import { createSlice } from "@reduxjs/toolkit";

import { SettingsState } from "../interfaces/Settings";

const initialState: SettingsState = {
  isLoading: true,
  settings: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setLoading, setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
