import React, { useEffect, useState } from "react";
import { Input, Table, Tooltip } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateSchedule from "../../../components/Modals/CreateSchedule";

import { firstLetterCap } from "../../../utils";
import { ScheduleType } from "../../../store/interfaces/Schedule";
import { getSchedule } from "../../../store/services/schedule";
import moment from "moment";
import { getGroupWishlists } from "../../../store/services/groupWishlist";
import CreateGroupWishlist from "../../../components/Modals/CreateGroupWishlist";
import { GroupWishlistType } from "../../../store/interfaces/GroupWishlist";
import ViewGroupWishlist from "../../../components/Modals/ViewGroupWishlist";
import GroupWishlistStatus from "../../../components/Modals/GroupWishlistStatus";

const { Search } = Input;

const GroupWishlist: React.FC = () => {
  const { isLoading, groupWishlistData } = useAppSelector(
    (state) => state.groupWishlist
  );
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, [page]);

  const getData = async () => {
    await getGroupWishlists();
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (_: string, obj: GroupWishlistType) => {
        const displayUsers = obj?.users.slice(0, 1);
        const otherUsers = obj?.users.slice(1);

        return (
          <Tooltip
            placement="top"
            title={otherUsers?.map((v) => `${v?.email}`).join(", ")}
          >
            <>
              {displayUsers?.map((v, index) => (
                <span key={index}>
                  {v?.email}
                  {index < displayUsers?.length - 1 && ", "}
                </span>
              ))}
              {otherUsers?.length > 0 && (
                <span>
                  <strong>, and {otherUsers?.length} more...</strong>
                </span>
              )}
            </>
          </Tooltip>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (_: string, obj: GroupWishlistType) => {
        return <p>{obj?.product?.productId?.name}</p>;
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (_: string, obj: GroupWishlistType) => {
        let value;
        if (obj?.product?.size) {
          const isSize = obj?.product?.productId?.variations?.find(
            (v) => v?._id === obj?.product?.size
          );
          value = isSize?.size;
        } else {
          value = "-";
        }
        return <p>{value}</p>;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (_: string, obj: GroupWishlistType) => {
        let value;
        if (obj?.product?.color) {
          const isSize = obj?.product?.productId?.variations?.find(
            (v) => v?._id === obj?.product?.size
          );
          const isColor = isSize?.colors?.find(
            (v) => v?._id === obj?.product?.color
          );
          value = isColor?.hex;
        } else {
          value = "";
        }
        return (
          <>
            {value !== "" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: value,
                    marginRight: "8px",
                  }}
                />
                <span>{value}</span>
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Active Status",
      dataIndex: "active",
      key: "active",
      render: (t: boolean) => (
        <p style={{ color: t ? "var(--mainGreen)" : "var(--mainRed)" }}>
          {t ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (t: string, obj: GroupWishlistType) => (
        <GroupWishlistStatus id={obj?._id} status={t} />
      ),
    },
    {
      title: "Initial Points",
      dataIndex: "initialPoints",
      key: "initialPoints",
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: GroupWishlistType) => (
        <div className="icon_box">
          <ViewGroupWishlist data={obj} />
          <DeleteModal
            type={"group-wishlist"}
            id={obj?._id}
            getData={getData}
          />
        </div>
      ),
    },
  ];

  const filteredGroupWishlist = groupWishlistData?.filter((v) =>
    v?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  return (
    <div className="user_container">
      <header>
        <h1>Group Wishlists</h1>
        <div>
          <CreateGroupWishlist getData={getData} />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search by name..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={filteredGroupWishlist}
          rowKey={(record) => record._id}
          scroll={{ x: 1000 }}
          pagination={{
            current: page,
            total: groupWishlistData?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default GroupWishlist;
