import React, { useState, useEffect } from "react";
import {
  getCountByStatus,
  getSaleStats,
} from "../../../../store/services/dashboard";
import { Col, DatePicker, Row, Spin } from "antd";
import type { DatePickerProps } from "antd";

import { Pie, Column } from "@ant-design/plots";
import { useAppSelector } from "../../../../store/hooks";
import dayjs from "dayjs";

const ChartSection: React.FC = () => {
  const monthFormat = "YYYY-MM";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const currentDate = `${year}-${month < 10 ? "0" + month : month}`;

  const { countByStatus, salesData } = useAppSelector(
    (state) => state.dashboard
  );
  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [statusDate, setStatusDate] = useState<string>(currentDate);
  const [salesDate, setSalesDate] = useState<string>(currentDate);

  useEffect(() => {
    _getStatusData();
    // eslint-disable-next-line
  }, [statusDate]);

  useEffect(() => {
    _getSalesData();
    // eslint-disable-next-line
  }, [salesDate]);

  const _getStatusData = async () => {
    !loading1 && setLoading1(true);
    const date = statusDate?.split("-");
    await getCountByStatus({
      month: Number(date[1]),
      year: Number(date[0]),
    });
    setLoading1(false);
  };
  const _getSalesData = async () => {
    !loading2 && setLoading2(true);
    const date = salesDate?.split("-");
    await getSaleStats({
      month: Number(date[1]),
      year: Number(date[0]),
    });
    setLoading2(false);
  };

  const handleCountByStatus: DatePickerProps["onChange"] = (_, dateString) => {
    setStatusDate(dateString);
  };
  const handleSalesStats: DatePickerProps["onChange"] = (_, dateString) => {
    setSalesDate(dateString);
  };

  const pieConfig = {
    appendPadding: 0,
    data: countByStatus,
    angleField: "value",
    colorField: "type",
  };

  const columnConfig = {
    data: salesData,
    isGroup: true,
    xField: "date",
    yField: "value",
    seriesField: "type",
    color: ["#1ca9e6", "#f88c24"],
    colorField: "type",
  };

  return (
    <div className="chart_section_container">
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={12}>
          <div className="chart_box">
            <header>
              <h3>Monthly Count By Status</h3>
              <DatePicker
                style={{ width: "150px" }}
                onChange={handleCountByStatus}
                picker="month"
                defaultValue={dayjs(statusDate, monthFormat)}
                format={monthFormat}
              />
            </header>
            {loading1 ? (
              <LoaderAndSpin type={"spin"} />
            ) : (
              <>
                {countByStatus?.length > 0 ? (
                  <div className="chart">
                    <Pie {...pieConfig} />
                  </div>
                ) : (
                  <LoaderAndSpin type={"text"} />
                )}
              </>
            )}
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="chart_box">
            <header>
              <h3>Monthly Sales Stats</h3>
              <DatePicker
                style={{ width: "150px" }}
                onChange={handleSalesStats}
                picker="month"
                defaultValue={dayjs(salesDate, monthFormat)}
                format={monthFormat}
              />
            </header>
            {loading2 ? (
              <LoaderAndSpin type={"spin"} />
            ) : (
              <>
                {salesData?.length > 0 ? (
                  <div className="chart">
                    <Column {...columnConfig} />
                  </div>
                ) : (
                  <LoaderAndSpin type={"text"} />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const LoaderAndSpin = ({ type }: { type: string }) => {
  return (
    <div className="loader_and_spin_box">
      {type === "spin" ? <Spin /> : "No data available"}
    </div>
  );
};

export default ChartSection;
