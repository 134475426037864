import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateReview from "../../../components/Modals/CreateReview";

import { firstLetterCap } from "../../../utils";
import { ReviewType } from "../../../store/interfaces/Review";
import { getReviews } from "../../../store/services/review";

const { Search } = Input;

const Review: React.FC = () => {
  const { isLoading, reviews } = useAppSelector((state) => state.review);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, [page]);

  const getData = async () => {
    await getReviews();
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (t: string) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              t ? t : require("../../../assets/images/user-icon.jpg")
            })`,
          }}
        ></div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      render: (t: string) => <p>{t?.toLocaleString()}</p>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: ReviewType) => (
        <div className="icon_box">
          <CreateReview isEdit editObj={obj} getData={getData} />
          <DeleteModal type={"review"} id={obj?._id} getData={getData} />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Reviews</h1>
        <div>
          <CreateReview getData={getData} />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={reviews?.filter((v) =>
            v?.name?.toLowerCase()?.includes(search?.toLowerCase())
          )}
          rowKey={(record) => record._id}
          scroll={{ x: 650 }}
          pagination={{
            current: page,
            total: reviews?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Review;
