import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";

import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { passwordPattern } from "../../../config/constants";

import { UserType } from "../../../store/interfaces/User";
import { useAppSelector } from "../../../store/hooks";
import { adminUpdate } from "../../../store/services/auth";

const Account: React.FC = () => {
  const { user: ADMIN } = useAppSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    form.setFields([
      { name: ["firstName"], value: ADMIN?.firstName },
      { name: ["lastName"], value: ADMIN?.lastName },
      { name: ["email"], value: ADMIN?.email },
    ]);
    // eslint-disable-next-line
  }, [ADMIN]);

  const onFinish = async (values: UserType) => {
    const firstName = values?.firstName;
    const lastName = values?.lastName;
    const email = values?.email;
    const password = values?.password;

    const obj = {
      firstName,
      lastName,
      email,
      password,
    };
    _updateUser(obj);
  };

  const _updateUser = async (payload: any) => {
    setLoading(true);
    const res = await adminUpdate(payload);
    if (res) {
      form.setFieldValue("password", undefined);
    }
    setLoading(false);
  };

  return (
    <div className="create_user_container">
      <header>
        <div>
          <IoArrowBack
            size={20}
            className="cursor"
            onClick={() => navigate(-1)}
          />
          <h1>Update Credential</h1>
        </div>
      </header>

      <div className="form_container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter your first name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Enter your last name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"email"}
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    type: "email",
                    message: "Invalid email address",
                  },
                ]}
              >
                <Input type="email" placeholder="Email Address (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"password"}
                label={
                  <p>
                    Password (
                    <span style={{ fontSize: "10px" }}>
                      One uppercase letter, one special character and one number
                    </span>
                    )
                  </p>
                }
                rules={[
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (value.length < 8) {
                        return Promise.reject(
                          "Password must be at least 8 characters"
                        );
                      }
                      if (passwordPattern.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Invalid password pattern (Password must contain atleast one uppercase letter, one special character and one number)"
                        );
                      }
                    },
                  },
                ]}
                validateTrigger={["onBlur"]}
              >
                <Input placeholder="Password (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Account;
