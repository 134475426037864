import React, { useEffect, useState } from "react";
import { Input, Table, Tooltip } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateLabel from "../../../components/Modals/CreateLabel";

import { firstLetterCap } from "../../../utils";
import { getLabels } from "../../../store/services/label";
import { LabelType } from "../../../store/interfaces/Label";

const { Search } = Input;

const Label: React.FC = () => {
  const { isLoading, labels } = useAppSelector((state) => state.label);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    // eslint-disable-next-line
  }, [page]);

  const getData = async () => {
    await getLabels();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (_: string, obj: LabelType) => {
        const displayUsers = obj?.users.slice(0, 1);
        const otherUsers = obj?.users.slice(1);

        return (
          <Tooltip
            placement="top"
            title={otherUsers?.map((v) => `${v?.email}`).join(", ")}
          >
            <>
              {displayUsers?.map((v, index) => (
                <span key={index}>
                  {v?.email}
                  {index < displayUsers?.length - 1 && ", "}
                </span>
              ))}
              {otherUsers?.length > 0 && (
                <span>
                  <strong>, and {otherUsers?.length} more...</strong>
                </span>
              )}
            </>
          </Tooltip>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: LabelType) => (
        <div className="icon_box">
          <CreateLabel isEdit editObj={obj} />
          <DeleteModal type={"label"} id={obj?._id} getData={() => {}} />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Labels</h1>
        <div>
          <CreateLabel />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={labels?.filter((v) =>
            v?.name?.toLowerCase()?.includes(search?.toLowerCase())
          )}
          rowKey={(record) => record._id}
          scroll={{ x: 800 }}
          pagination={{
            current: page,
            total: labels?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Label;
