import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "../../store";

import { Login } from "../interfaces/Auth";

import { clearUser, setUser } from "../slices/authSlice";

export const adminLogin = async (payload: Login) => {
  try {
    const response = await privateAPI.post("/admin/login", payload);
    if (response) {
      store.dispatch(setUser(response?.data?.data));
      localStorage.setItem("token", response?.data?.data?.token);
    }
    return response?.data;
  } catch (error: any) {
    console.log("Login Error:", error?.response?.data?.message);
    notification.error({
      message: error?.response?.data?.message,
    });
  }
};

export const getAdmin = async () => {
  try {
    attachToken();
    const response = await privateAPI.get("/admin/get");
    if (response) {
      store.dispatch(setUser(response?.data?.data));
      localStorage.setItem("token", response?.data?.data?.token);
    }
    return response?.data;
  } catch (error: any) {
    console.log("Login Error:", error?.response?.data?.message);
    notification.error({
      message: error?.response?.data?.message,
    });
  }
};

export const adminUpdate = async (payload: Login) => {
  // attachToken();
  try {
    const response = await privateAPI.put("/admin/update", payload);
    if (response) {
      await getAdmin();
      notification.success({
        message: response?.data?.data,
      });
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message,
    });
  }
};

export const logout = async () => {
  localStorage.clear();
  store.dispatch(clearUser());
};
