import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import {
  setCategory,
  setLoading,
  setSubCategories,
  setTypeCategories,
} from "../slices/categorySlice";
import { CreateCategoryType, GetCategoryByType } from "../interfaces/Category";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Category Created Successfully";
const Updated = "Category Updated Successfully";
const Deleted = "Category Deleted Successfully";

export const createCategory = async (payload: CreateCategoryType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/category/create", payload);
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      getAllCategory();
      return response?.data;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getCategoriesByType = async (payload: GetCategoryByType) => {
  try {
    attachToken();
    const response = await privateAPI.post("/category/get", payload);
    if (response) {
      store.dispatch(setTypeCategories(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSubCategories = async (payload: GetCategoryByType) => {
  try {
    attachToken();
    const response = await privateAPI.post("/category/get", payload);
    if (response) {
      store.dispatch(setSubCategories(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getAllCategory = async () => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/category/get-all");
    if (response) {
      store.dispatch(setCategory(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateCategory = async (payload: CreateCategoryType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/category/update", payload);
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Updated,
      });
      getAllCategory();
      return response?.data;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteCategory = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/category/delete?_id=${id}`);
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
