import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import {
  setBestProducts,
  setCountByStatus,
  setDashboardAnalytic,
  setLoading,
  setSalesData,
  setTopUsers,
} from "../slices/dashboardSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

interface DatePayload {
  month: number;
  year: number;
}

export const getDashboardAnalytic = async () => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/analytic/");
    if (response) {
      store.dispatch(setDashboardAnalytic(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getBestSellingProduct = async (payload: DatePayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post(
      "/product/get-top-10-selling-products",
      payload
    );
    if (response) {
      store.dispatch(setBestProducts(response?.data?.data?.products));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getTopUsers = async () => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/auth/get-top-10-users");
    if (response) {
      store.dispatch(setTopUsers(response?.data?.data?.users));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getCountByStatus = async (payload: DatePayload) => {
  try {
    attachToken();
    const response = await privateAPI.post("/order/count-by-status", payload);
    if (response) {
      store.dispatch(setCountByStatus(response?.data?.data?.orderStatusCount));
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSaleStats = async (payload: DatePayload) => {
  try {
    attachToken();
    const response = await privateAPI.post("order/sales-stats", payload);
    if (response) {
      store.dispatch(setSalesData(response?.data?.data?.salesData));
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
