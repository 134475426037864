import { createSlice } from "@reduxjs/toolkit";
import { RewardState } from "../interfaces/Reward";

const initialState: RewardState = {
  isLoading: true,
  rewards: [],
};

export const rewardSlice = createSlice({
  name: "reward",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setRewards: (state, action) => {
      state.rewards = action.payload;
    },
  },
});

export const { setRewards, setLoading } = rewardSlice.actions;

export default rewardSlice.reducer;
