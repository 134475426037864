import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { setLoading, setBadges } from "../slices/badgeSlice";
import { CreateBadgeType } from "../interfaces/Badge";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Badge Created Successfully";
const Updated = "Badge Updated Successfully";
const Deleted = "Badge Deleted Successfully";

export const createBadge = async (payload: CreateBadgeType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/badge/create", payload);
    if (response) {
      await getBadges();
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getBadges = async () => {
  try {
    store.dispatch(setLoading(true));
    attachToken();
    const response = await privateAPI.get("/badge/get");
    if (response) {
      store.dispatch(setBadges(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const updateBadge = async (payload: CreateBadgeType) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.put("/badge/update", payload);
    if (response) {
      await getBadges();
      notification.success({
        message: response?.data?.data?.message || Updated,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const deleteBadge = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/badge/delete`, { _id: id });
    if (response) {
      await getBadges();
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
    }
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
