import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";

import { useAppSelector } from "../../../store/hooks";

import { CartProductType, CartType } from "../../../store/interfaces/Cart";
import { getCarts } from "../../../store/services/cart";

import DeleteModal from "../../../components/Modals/DeleteModal";
import ViewCartDetails from "../../../components/Modals/ViewCartDetails";

import { firstLetterCap } from "../../../utils";

const { Search } = Input;

const Cart: React.FC = () => {
  const { isLoading, carts, total } = useAppSelector((state) => state.cart);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(search);
    // eslint-disable-next-line
  }, [page]);

  const getData = async (search?: string) => {
    await getCarts({
      search: search || "",
      page,
      perPage,
    });
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 70,
      render: (_: string, obj: CartType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.user?.imageUrl
                ? obj?.user?.imageUrl
                : require("../../../assets/images/user-icon.jpg")
            })`,
          }}
        ></div>
      ),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, obj: CartType) => (
        <p>
          {obj?.user?.firstName} {obj?.user?.lastName}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_: string, obj: CartType) => (
        <p>{obj?.user?.phoneNumber || "-"}</p>
      ),
    },
    {
      title: "Number of Product",
      dataIndex: "numberOfProduct",
      key: "numberOfProduct",
      render: (_: string, obj: CartType) => <p>{obj?.products?.length}</p>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: CartType) => (
        <div className="icon_box">
          <DeleteModal type={"cart-all"} id={obj?._id} getData={getData} />
        </div>
      ),
    },
  ];

  const productsColumns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 70,
      render: (_: string, obj: CartProductType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.product?.imagesUrl?.length > 0
                ? obj?.product?.imagesUrl[0]
                : require("../../../assets/images/box.png")
            })`,
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, obj: CartProductType) => <p>{obj?.product?.name}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: string, obj: CartProductType) => (
        <p>{obj?.product?.description}</p>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (_: string, obj: CartProductType) => (
        <p>{firstLetterCap(obj?.product?.category?.name)}</p>
      ),
    },
    {
      title: "Sub Category",
      dataIndex: "category",
      key: "category",
      render: (_: string, obj: CartProductType) => (
        <p>{firstLetterCap(obj?.product?.subCategory?.name)}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: string, obj: CartProductType) => (
        <div className="icon_box">
          <ViewCartDetails data={obj} />
          <DeleteModal
            type={"cart-one"}
            id={String(obj?.parentId)}
            productId={obj?._id}
            getData={getData}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Carts</h1>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          onSearch={(value) => getData(value)}
          enterButton
          allowClear
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            value === "" && getData("");
          }}
        />
      </div>

      <div className="user_table_container">
        <Table
          loading={isLoading}
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={carts}
          expandable={{
            expandedRowRender: (record) => (
              <div
                className="user_table_container expanded_table"
                style={{ marginTop: "0px" }}
              >
                <Table
                  rowKey={(product) => product._id}
                  columns={productsColumns}
                  dataSource={record?.products?.map((v) => {
                    return { ...v, parentId: record?._id };
                  })}
                  pagination={false}
                />
              </div>
            ),
            rowExpandable: (record) => record.products.length > 0,
          }}
          scroll={{ x: 1000 }}
          pagination={{
            current: page,
            total: total,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Cart;
